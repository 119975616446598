import { useGlobalState } from '../../lib/globalStateProvider';

const BrandLogo = () => {
  const { currentEvent } = useGlobalState();
  if (
    currentEvent == null ||
    currentEvent.image_path == null ||
    !currentEvent.image_path.startsWith('http')
  ) {
    return null;
  }
  return <img src={currentEvent.image_path} style={{ maxHeight: '100px' }} />;
};

export default BrandLogo;
