import { useEffect, useState } from 'react';
import QRCode from 'qrcode';
import styled from 'styled-components';

import { ScoreTable } from '../../lib/types/db-models';
import { LoaderWithMessage } from '../Loader';
// import { Card } from './Login';

interface ScoreQrDialogProps {
  scoreId: number;
  score: number;
  pos: number;
  _score?: ScoreTable;
}

const ScoreQrDialog = ({
  scoreId,
  score,
  pos,
}: ScoreQrDialogProps): JSX.Element => {
  const [qr, setQr] = useState<string | null>(null);

  useEffect(() => {
    const baseUrl = 'https://' + 'scores.arcadestrange.com';
    // 'homer-scoreboard-git-feature-qr-dialog-hortonline.vercel.app';
    const url = `${baseUrl}/claim/${scoreId}?score=${score}&pos=${pos}`;
    QRCode.toDataURL(url)
      .then((url) => {
        setQr(url);
        console.log(url);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  if (!qr) {
    return (
      <Wrapper>
        <LoaderWithMessage message="Generating QR Code" />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <img src={qr} />
    </Wrapper>
  );
};

export default ScoreQrDialog;

const Wrapper = styled.div`
  width: 196px;
  height: 196px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
