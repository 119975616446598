import styled, { useTheme } from 'styled-components';

const BackgroundVideo = (): JSX.Element => {
  const theme = useTheme();
  return (
    <Wrapper>
      <FadeOutGrad />
      <FadeOutGradBottom />
      <video src={theme.media.backgroundVideo} muted autoPlay loop />
    </Wrapper>
  );
};

export default BackgroundVideo;

const Wrapper = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  video {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
`;

const FadeOutGrad = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 300px;
  background: linear-gradient(
    to bottom,
    ${(p) => p.theme.colors.bg},
    transparent
  );
  z-index: 2;
`;

const FadeOutGradBottom = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 300px;
  background: linear-gradient(to top, ${(p) => p.theme.colors.bg}, transparent);
  z-index: 2;
`;
