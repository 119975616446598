import { useState } from 'react';
import styled from 'styled-components';

import { supabase } from '../../lib/db/client';
import { Account, Table } from '../../lib/types/db-models';
import { useGlobalState } from '../../lib/globalStateProvider';
import useLocalStorage from '../../lib/useLocalStorage';

import Button from '../Button';
import Input from '../Input';
import { BlurASLogo } from '../icons/ASLogo';

const Login = () => {
  const [email, setEmail] = useLocalStorage('account-login', 'synergy');
  const [password, setPassword] = useState('');
  const { setAccount } = useGlobalState();
  const handleClick = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    let emailToLoginWith;
    if (email === 'synergy') {
      // temp quality of life improvement for synergy
      emailToLoginWith = 'arcade.strange.one@gmail.com';
    } else if (email === 'strangethoughts') {
      emailToLoginWith = 'joseph@hort.onl';
    } else emailToLoginWith = email;
    const { user } = await supabase.auth.signIn({
      email: emailToLoginWith,
      password,
    });
    console.log({ user });
    if (user) {
      const { data: account } = await supabase
        .from<Account>(Table.ACCOUNTS)
        .select('*')
        .eq('id', user.id)
        .single();

      if (account) {
        setAccount({
          id: account.id,
          username: account.username,
          connection_id: account.connection_id,
        });
      }
    }
  };
  return (
    <LoginContainer>
      <Inner>
        <LogoContainer>
          <BlurASLogo />
        </LogoContainer>
        <p>Please log in to your account to continue</p>
        <Form>
          <FormItem>
            <label>Account</label>
            <Input
              type="text"
              placeholder="account@arcadestrange.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormItem>
          <FormItem>
            <label>Password</label>
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormItem>
          <Button onClick={handleClick}>Login</Button>
        </Form>
      </Inner>
    </LoginContainer>
  );
};

export default Login;

const LoginContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
`;

const LogoContainer = styled.div`
  width: 80%;
  max-width: 300px;
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 100%;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  max-width: 400px;
  align-items: center;
`;

const FormItem = styled.div`
  display: flex;
  flex-direction: column;

  gap: 6px;

  width: 100%;

  label {
  }
`;
