import { useState } from 'react';

import { useWebSocket } from './sockets/ws-provider';
import useInterval from './useInterval';
import { mapRange } from './utils';
import perlin from './perlin';
import { useGlobalState } from './globalStateProvider';

interface UseMindControl {
  focusLevel: number;
  headsetDetected: boolean;
  isRunning: boolean;
  start: () => void;
  pause: () => void;
}

const useMindControl = (useMockedData = false): UseMindControl => {
  const ws = useWebSocket();
  const { localHeadsetConnected, localHeadsetFocus } = useGlobalState();
  const [focusLevel, setFocusLevel] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  // FIXME: another method for finding if headset is detected,
  // poorSignalLevel, or request a value greater than 0
  const headsetDetected = !isRunning && focusLevel > 0;

  const start = () => {
    setIsRunning(true);
    console.log('start mind control');
  };

  const pause = () => {
    setIsRunning(false);
    console.log('pause mind control');
  };

  useInterval(
    () => {
      if (!useMockedData) {
        if (
          ws.lastMessage.type === 'mind_reading' &&
          typeof ws.lastMessage.data != 'object' &&
          +ws.lastMessage.data > 0
        ) {
          const val =
            typeof ws.lastMessage.data === 'string'
              ? parseFloat(ws.lastMessage.data)
              : ws.lastMessage.data;
          // We could consider using ws.lastMessage.signalLevel here to speed up headset recognition
          setFocusLevel(val);
        } else if (localHeadsetConnected) {
          setFocusLevel(localHeadsetFocus);
        }
      } else {
        // Mocked data
        // focusLevel.current = mapRange(Math.random(), 0.0, 1.0, 0.0, 100.0);
        const now = performance.now() / 2000;
        let perlinFocus = perlin.get(now, now) + 0.7;
        if (perlinFocus > 1) perlinFocus = 1;
        const mapped = mapRange(perlinFocus, -1.0, 1.0, 0.0, 100.0);

        // const mapped = mapRange(Math.random(), 0.0, 1.0, 0.0, 100.0);

        setFocusLevel(mapped);
      }
    },
    !headsetDetected ? 200 : isRunning ? 48 : null
  );

  return {
    focusLevel: focusLevel,
    headsetDetected,
    isRunning,
    start,
    pause,
  };
};

export default useMindControl;
