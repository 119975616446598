export interface Event {
  id: number;
  created_at: string;
  name: string;
  image_path: string;
  beer_icon_path: string | null;
  beer_icon_size: number | null;
  body_left_image_path: string | null;
  body_right_image_path: string | null;
  body_image_tint: boolean;
  leaderboard_cut_off: string | null;
}

export interface ScoreTable {
  id: number;
  created_at: string;
  name: string;
  score: number;
  mode: string;
  event_id: number;
}

export enum Table {
  SCORE = 'score_test',
  EVENT = 'event',
  ACCOUNTS = 'accounts',
  POURS = 'pours',
  EMAIL = 'emails',
}

export interface Account {
  id: string;
  username: string;
  connection_id: string;
}

type PourType = 'Mind' | 'Body' | 'Button' | 'Race';

export interface Pour {
  id?: number;
  created_at: string;
  score: number;
  completed: boolean;
  type: PourType;
  name: string;
  mode: string;
  skin: string;
  difficulty: string;
  account_id: string;
  claimed: boolean;
  event_id: number;
}
