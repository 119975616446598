import styled from 'styled-components';
import { useQuery } from 'react-query';

import { supabase } from '../../lib/db/client';
import { Pour, Table } from '../../lib/types/db-models';
import { GameMode } from '../../lib/types/game-types';
import { useGlobalState } from '../../lib/globalStateProvider';

interface TopScoresProps {
  mode: GameMode;
  accountId: string;
  skin?: string;
  unit: string | null;
}

const fetchTopScores = async (
  mode: GameMode,
  skin: string,
  accountId: string,
  eventId: number,
  cutoff: string | null
): Promise<Pour[] | null> => {
  const { data, error } = await supabase
    .from<Pour>(Table.POURS)
    .select('*')
    .match({
      mode,
      skin,
      claimed: true,
      account_id: accountId,
      event_id: eventId,
    })
    .gt('created_at', cutoff ?? '1900-01-01')
    .limit(3)
    .order('score', { ascending: false });

  if (error) throw new Error(error.message);

  return data;
};

// TODO: use account ID when we have additional clients
const TopScores = ({
  mode,
  accountId,
  skin,
  unit,
}: TopScoresProps): JSX.Element => {
  const { currentEvent } = useGlobalState();
  if (currentEvent == null) {
    throw new Error('No event selected');
  }
  const scores = useQuery(
    ['top-scores', mode, skin],
    () =>
      fetchTopScores(
        mode,
        skin ?? '',
        accountId,
        currentEvent.id,
        currentEvent.leaderboard_cut_off
      ),
    { enabled: accountId.length > 0 }
  );
  if (scores.isSuccess && scores.data && scores.data.length > 0) {
    return (
      <ScoresWrapper>
        <p style={{ textAlign: 'center', marginBottom: '4px' }}>
          Top 3 {mode} Scores
        </p>
        {scores.data.map((score, index) => (
          <div className="inner" key={score.id ?? index}>
            <span>{index + 1}</span>
            <span>{score.name}</span>
            <span style={{ textAlign: 'right' }}>
              {unit !== null ? `${score.score.toFixed(2)}${unit}` : score.score}
            </span>
          </div>
        ))}
      </ScoresWrapper>
    );
  }
  return <></>;
};

export default TopScores;

const ScoresWrapper = styled.div`
  color: white;
  font-family: 'Bevellier', 'Inter', sans-serif;
  width: 100%;
  font-size: 24px;

  max-width: 180px;
  margin-top: 20px;
  .inner {
    display: grid;
    grid-template-columns: 32px 1fr 1fr;
  }
`;
