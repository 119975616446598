/* eslint-disable @typescript-eslint/no-explicit-any */
import { Color, Image, Vector } from 'p5';
import { P5CanvasInstance } from '@p5-wrapper/react';
import { Side } from './catcher';
import { GameProps } from './main';

export class Dropper {
  pos: Vector;
  acc: Vector;
  origin: Vector;
  vel: Vector;
  radius: number;
  isCaught: boolean;
  p5: P5CanvasInstance<GameProps>;
  col: Color;
  d: number;
  side: Side;
  droplets: Droplet[];
  dropletsGone: boolean;
  caughtCorrectly: boolean;
  frameDelay: number;
  frameDelayCounter: number;

  constructor(
    p5: P5CanvasInstance<GameProps>,
    speedMultiplier: number,
    delay = 0
  ) {
    this.p5 = p5;
    this.side = flipCoin() ? 'l' : 'r';
    this.col =
      this.side == 'l'
        ? this.p5.color(129, 251, 248)
        : this.p5.color(253, 81, 226);

    const speedLimit = 0.3 * speedMultiplier;
    const initialX =
      this.side === 'l'
        ? this.p5.random(-speedLimit, 0.0)
        : this.p5.random(-0.0, speedLimit);
    const initialY = this.p5.random(-speedLimit, speedLimit);

    this.acc = this.p5.createVector(initialX, initialY);
    this.origin = this.p5.createVector(this.p5.width / 2, this.p5.height / 2);
    this.pos = this.origin.copy();
    this.vel = this.p5.createVector(0, 0);
    this.radius = 60;
    this.isCaught = false;
    this.d = 0;
    this.droplets = Array.from(
      { length: 3 },
      () => new Droplet(p5, this.pos.x, this.pos.y)
    );
    this.dropletsGone = false;
    this.caughtCorrectly = false;
    this.frameDelay = delay;
    this.frameDelayCounter = 0;
  }

  draw(image?: Image, tint?: boolean): void {
    if (this.isCaught) {
      let dead = true;
      this.droplets.forEach((d) => {
        if (!d.positionSet)
          d.init(this.pos.x, this.pos.y, this.caughtCorrectly);
        d.draw();
        if (d.life > 0) dead = false;
      });
      this.dropletsGone = dead;
    } else {
      if (this.frameDelayCounter < this.frameDelay) {
        this.frameDelayCounter++;
        return;
      }
      const force = this.origin.copy();
      force.sub(this.pos);
      let d = -force.mag();
      d = this.p5.constrain(d, 4, 6);

      const strength = -1 / (d * d);
      force.setMag(strength);
      this.acc.add(force);

      this.vel.add(this.acc);
      this.pos.add(this.vel);
      // TODO with TOM: find an optimal value for velocity limit
      this.vel.limit(6);
      this.acc.mult(0);

      const dist = this.pos.dist(this.origin);
      this.radius = this.p5.constrain(dist / 1.5, 1, 600);

      if (image) {
        this.p5.push();
        if (tint) {
          this.p5.tint(this.col);
        }
        let width = this.radius;
        let height = this.radius;
        if (image.width > image.height) {
          height = image.height * (width / image.height);
        } else {
          width = image.width * (height / image.width);
        }
        this.p5.image(
          image,
          this.pos.x - width / 2,
          this.pos.y - height / 2,
          width,
          height
        );
        this.p5.pop();
      } else {
        this.p5.noStroke();
        this.p5.fill(this.col);
        this.p5.circle(this.pos.x, this.pos.y, this.radius);
      }
    }
  }

  hasReachedBottom(): boolean {
    return (
      this.pos.y > this.p5.height ||
      this.pos.y < 0 ||
      this.pos.x > this.p5.width ||
      this.pos.x < 0
    );
  }

  caught(hand: Side): boolean | null {
    if (this.isCaught) return null;
    this.caughtCorrectly = hand === this.side;
    this.isCaught = true;
    return this.caughtCorrectly;
  }
}

const flipCoin = () => Math.random() <= 0.5;

const grn = [86, 253, 163];
const rd = [253, 86, 86];

class Droplet {
  p5: P5CanvasInstance;
  pos: Vector;
  origin: Vector;
  positionSet: boolean;
  mass: number;
  velocity: Vector;
  acceleration: Vector;
  life: number;
  correct: boolean;
  col: number[];

  constructor(p5: P5CanvasInstance<any>, _x: number, _y: number) {
    this.p5 = p5;
    this.pos = p5.createVector(_x, _y);
    this.origin = p5.createVector(_x, _y);
    this.positionSet = false;
    this.mass = 1.2;
    this.velocity = p5.createVector(0, 0);
    this.acceleration = p5.createVector(0, 0);
    this.life = 255;
    this.correct = false;
    this.col = grn;
  }

  init(_x: number, _y: number, _correct: boolean) {
    const offset = 30;
    this.pos.set(
      _x + this.p5.random(-offset, offset),
      _y + this.p5.random(-offset, offset)
    );
    this.origin.set(_x, _y);
    // whether parent drop was caught with correct hand
    this.correct = _correct;
    this.col = this.correct ? grn : rd;
    this.positionSet = true;
  }

  draw() {
    if (this.life < 0) return;
    if (this.correct) {
      const force = this.origin.copy();
      force.sub(this.pos);
      let d = force.mag();
      d = this.p5.constrain(d, 1, 8);
      const g = -1;
      const strength = (g * this.mass) / (d * d);
      force.setMag(strength);

      force.div(this.mass);
      this.acceleration.add(force);
    } else {
      this.acceleration.add(0, 0.5);
    }

    this.velocity.add(this.acceleration);
    this.pos.add(this.velocity);
    this.acceleration.mult(0);

    this.p5.fill([...this.col, this.life]);
    this.p5.circle(this.pos.x, this.pos.y, this.mass * 10);
    this.life -= 5;
  }
}
