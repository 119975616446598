import styled from 'styled-components';

const noise = 'url(/images/grain.svg)';

const Button = styled.button`
  background: none;
  outline: none;
  border: none;
  color: #fff;
  padding: 12px 64px;

  text-transform: uppercase;
  /* font-weight: 600; */
  /* font-size: 20px;
  font-family: 'Bevellier', 'Inter', sans-serif;
  letter-spacing: 0.105em;
  background-color: ${(p) => p.theme.colors.bg}88;

  background: ${noise},
    linear-gradient(
      93.41deg,

    );
  box-shadow: 0px -1px 0px 0.1px #ffffff, 0px 1px 0px #000000;
  border-radius: 16px;
  position: relative;
  width: 100%;
  filter: contrast(140%) brightness(110%); */
  background-color: ${(p) => p.theme.colors.primary};
  font-family: Ready player one, sans-serif;
  font-size: 40px;
  line-height: 40px;
  box-shadow: 4px 4px #fff;
`;

export default Button;

export const ButtonContainer = styled.button`
  background: none;
  outline: none;
  border: none;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
