import { useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useTimer } from 'react-timer-hook';

interface CountdownProps {
  target?: number;
  onEndCallback?: () => void;
  started: boolean;
  useCountdownText?: boolean;
}

const Countdown = ({
  target = 3,
  onEndCallback,
  started,
  useCountdownText = false,
}: CountdownProps): JSX.Element => {
  const time = new Date();
  time.setSeconds(time.getSeconds() + target);

  const onStart = () => {
    time.setSeconds(time.getSeconds() + target);
    console.log('starting timer...');
    start();
  };

  const onEnd = () => {
    console.log('timer ended');
    if (onEndCallback) onEndCallback();
  };

  const { start, seconds, isRunning } = useTimer({
    expiryTimestamp: time,
    onExpire: onEnd,
    autoStart: false,
  });

  useEffect(() => {
    if (started && !isRunning) onStart();
  }, [started]);

  const text =
    seconds === 3
      ? 'ready'
      : seconds === 2
      ? 'set'
      : seconds === 1
      ? 'think!'
      : '';

  return (
    <>
      {seconds > 0 && (
        <Container>
          <motion.h1
            initial={{ scale: 0.95 }}
            animate={{ scale: 1.5 }}
            key={seconds}
          >
            {useCountdownText ? text : seconds}
          </motion.h1>
        </Container>
      )}
    </>
  );
};

export default Countdown;

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 6;

  background-color: rgba(20, 20, 20, 0.6);

  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    text-align: center;
    color: ${(p) => p.theme.colors.primary};
    font-variant-numeric: tabular-nums;
    font-size: 60px;
  }
`;
