import { useRef } from 'react';
import styled from 'styled-components';

interface LongPressDetectorProps {
  callback: () => void;
  requiredClicks?: number;
}

const LongPressDetector = ({
  callback,
  requiredClicks = 5,
}: LongPressDetectorProps): JSX.Element => {
  const clickCounter = useRef(0);
  const callbackCalled = useRef(false);

  const increment = () => {
    if (clickCounter.current === 0) {
      setTimeout(() => (clickCounter.current = 0), 3000);
    }
    if (callbackCalled.current === false) clickCounter.current += 1;
    if (clickCounter.current >= requiredClicks) callback();

    console.log(clickCounter.current);
  };
  return <ClickArea onClick={increment} started={clickCounter.current > 0} />;
};

export default LongPressDetector;

const ClickArea = styled.div<{ started: boolean }>`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 64px;
  height: 64px;
  background-color: ${(p) => p.theme.colors.secondary};
  opacity: ${(p) => (p.started ? 0.4 : 0)};
  z-index: 10;
`;
