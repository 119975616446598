import { DefaultTheme } from 'styled-components';

const appTheme: DefaultTheme = {
  dimensions: {
    navHeight: '102px',
    mindCircle: '300px',
  },
  media: {
    backgroundVideo: '/videos/star_loop.mp4',
  },
  colors: {
    primary: '#ed027e',
    secondary: '#6cbe45',
    bg: '#111',
    paneBorder: '#fff',
    textPrimary: '#fff',
    paneShadow: '#ed027e88',
    dialogBorder: '#6FFFF6',
    buttonGradient: [
      {
        color: '#6f1042',
        offset: '0%',
      },
      {
        color: '#ed027e',
        offset: '20.6%',
      },
      {
        color: '#f2f0ff',
        offset: '50.8%',
      },
      {
        color: '#44d62c',
        offset: '76.8%',
      },
      {
        color: '#1c5b11',
        offset: '100%',
      },
    ],
    inputGradient: [
      {
        color: '#1d1c20',
        offset: '0%',
      },
      {
        color: '#ff52e3',
        offset: '20.6%',
      },
      {
        color: '#f2f0ff',
        offset: '50.8%',
      },
      {
        color: '#6ffff6',
        offset: '76.8%',
      },
      {
        color: '#1f1a3e',
        offset: '100%',
      },
    ],
    gameTitleGradient: [
      {
        color: 'rgba(84, 190, 203, 1)',
        offset: '0%',
      },
      {
        color: 'rgba(84, 190, 203, 1)',
        offset: '50%',
      },
      {
        color: 'rgba(231, 245, 252, 1)',
        offset: '51%',
      },
      {
        color: 'rgba(231, 245, 252, 1)',
        offset: '60%',
      },
      {
        color: 'rgba(255, 82, 227, 1)',
        offset: '85%',
      },
    ],
  },
};

export default appTheme;
