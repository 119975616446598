import { Event } from '../types/db-models';
import { supabase } from './client';

const populateEventImage = async (
  value: string | null | undefined
): Promise<string> => {
  if (value === undefined || value === null) {
    return '';
  }
  if (typeof value !== 'string') {
    return '';
  }
  if (value === '') {
    return '';
  }
  if (!value.startsWith('http://') && !value.startsWith('https://')) {
    const { data } = await supabase.storage
      .from('branding')
      .getPublicUrl(value.replace(/^\//, ''));
    if (data && data.publicURL) {
      return data.publicURL;
    }
  }
  return value;
};

const populateEventLogos = async (event: Event) => {
  event.image_path = await populateEventImage(event.image_path);
  event.beer_icon_path = await populateEventImage(event.beer_icon_path);
  event.body_left_image_path = await populateEventImage(
    event.body_left_image_path
  );
  event.body_right_image_path = await populateEventImage(
    event.body_right_image_path
  );
  return event;
};

export default populateEventLogos;
