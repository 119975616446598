/* eslint-disable @typescript-eslint/no-explicit-any */
import { Image } from 'p5';
import { P5CanvasInstance } from '@p5-wrapper/react';
import { Dropper } from './dropper';
import { GameProps } from './main';

export type Side = 'r' | 'l';
const leftColor = [129, 251, 248];
const rightColor = [253, 81, 226];

export class Catcher {
  x: number;
  y: number;
  radius: number;
  innerRadius: number;
  ease: number;
  p5: P5CanvasInstance<GameProps>;
  col: number[];
  d: number;
  side: Side;
  handImage: Image;

  constructor(p5: P5CanvasInstance<GameProps>, side: Side, image: Image) {
    // start off screen
    this.x = -32;
    this.y = -32;
    this.radius = 100;
    this.innerRadius = this.radius * 0.33;
    this.ease = 0.5;
    this.p5 = p5;
    this.d = 0;
    this.side = side;

    this.handImage = image;

    // this.col = side === 'l' ? p5.color(194, 61, 220) : p5.color(254, 248, 106);
    this.col = this.side == 'l' ? leftColor : rightColor;
  }

  update(x_: number, y_: number): void {
    const xDiff = x_ - this.x;
    const yDiff = y_ - this.y;

    this.x += xDiff * this.ease;
    this.y += yDiff * this.ease;

    // To hold catchers in center of screen for debugging
    const debug = false;
    if (debug) {
      const add = this.side == 'l' ? -100 : 100;
      this.x = this.p5.width / 2 + add;
      this.y = this.p5.height / 2;
    }
  }

  draw(): void {
    this.p5.noStroke();
    // draw inner circle
    this.p5.fill(this.col);
    this.p5.ellipse(this.x, this.y, this.innerRadius, this.innerRadius, 30);

    // draw outer circle
    this.d = this.d + 1 * (this.p5.deltaTime / 10);
    if (this.d > this.radius) this.d = 0;
    const opacity = this.p5.map(this.d, 0, this.radius, 220, 0);
    this.p5.fill([...this.col, opacity]);
    this.p5.ellipse(this.x, this.y, this.d, this.d, 30);

    this.p5.fill(255);
    const imgSize = 32;
    this.p5.image(
      this.handImage,
      this.x - imgSize / 2,
      this.y - imgSize / 2,
      imgSize,
      imgSize
    );
  }

  intersects(d: Dropper): boolean {
    const distance = this.p5.dist(this.x, this.y, d.pos.x, d.pos.y);
    return distance < d.radius / 2 + this.radius / 2;
  }
}
