import { useMutation, UseMutationResult } from 'react-query';
import { Pour, Table } from '../types/db-models';
import { GameDifficulty, PourModes } from '../types/game-types';
import { supabase } from './client';

const createPour = async (
  id: string,
  eventId: number,
  mode: PourModes,
  skin?: string,
  d?: GameDifficulty
): Promise<number> => {
  const { data, error } = await supabase
    .from<Pour>(Table.POURS)
    .insert({
      account_id: id,
      event_id: eventId,
      completed: false,
      mode,
      skin,
      difficulty: d,
    })
    .single();

  if (error) throw new Error(error.message);

  if (data && data.id) return data.id;

  return -1;
};

interface CreatePourParams {
  id: string;
  eventId: number;
  m: PourModes;
  d?: GameDifficulty;
}

const useCreatePour = (
  id: string,
  eventId: number,
  m: PourModes,
  skin?: string,
  d?: GameDifficulty
): UseMutationResult<number, Error, CreatePourParams> => {
  return useMutation('create-pour', () => createPour(id, eventId, m, skin, d), {
    onSuccess: () => console.log('create-pour success'),
  });
};

export default useCreatePour;
