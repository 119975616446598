import { createContext, useCallback, useContext, useState } from 'react';
import { useGlobalState } from './globalStateProvider';

interface PersonalDetailsState {
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  isNameValid: boolean;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  isEmailValid: boolean;
  reset: () => void;
  confirm: () => void;
  confirmed: boolean;
}

export const PersonalDetailsContext = createContext({
  name: '',
  email: '',
} as PersonalDetailsState);

export const usePersonalDetails = (): PersonalDetailsState =>
  useContext(PersonalDetailsContext);

export const PersonalDetailsProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const { collectEmail } = useGlobalState();
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [confirmed, setConfirmed] = useState<boolean>(false);

  const reset = useCallback(() => {
    setName('');
    setEmail('');
    setConfirmed(false);
  }, []);

  const isNameValid = name.length > 0 && name.length <= 10;
  const isEmailValid = !collectEmail || !email || /^(.+)@(.+)$/.test(email);

  const confirm = useCallback(() => {
    if (isNameValid && isEmailValid) {
      setConfirmed(true);
    }
  }, [isNameValid, isEmailValid]);

  return (
    <PersonalDetailsContext.Provider
      value={{
        name,
        setName,
        isNameValid,
        email,
        setEmail,
        isEmailValid,
        reset,
        confirm,
        confirmed,
      }}
    >
      {children}
    </PersonalDetailsContext.Provider>
  );
};
