import { P5CanvasInstance, SketchProps } from '@p5-wrapper/react';

class VaporGrid<Props extends SketchProps = SketchProps> {
  p5: P5CanvasInstance<Props>;
  offsetsTop: number[];
  offsetsBottom: number[];
  numVert: number;
  numHoriz: number;

  constructor(p5: P5CanvasInstance<Props>) {
    this.p5 = p5;
    this.numVert = 7;
    this.numHoriz = 11;
    this.offsetsBottom = Array.from(
      { length: this.numVert },
      (v, i) => (i * (this.p5.height / 2)) / this.numVert
    );
    this.offsetsTop = Array.from(
      { length: this.numVert },
      (v, i) => (i * (this.p5.height / 2)) / this.numVert
    );
  }

  draw(focusLevel?: number) {
    this.p5.strokeWeight(4);
    this.p5.noFill();
    this.p5.stroke(200, 40, 192);

    this.drawGrid(
      this.p5.height / 2,
      this.p5.height,
      this.numHoriz,
      this.numVert,
      'bottom',
      this.offsetsBottom,
      focusLevel ?? 0
    );
    this.drawGrid(
      0,
      this.p5.height / 2,
      this.numHoriz,
      this.numVert,
      'top',
      this.offsetsTop,
      focusLevel ?? 0
    );
    this.drawFill();
  }

  drawGrid = (
    minY: number,
    maxY: number,
    numX: number,
    numY: number,
    side: 'top' | 'bottom',
    offsets: number[],
    focusLevel: number
  ) => {
    const scrollSpeed =
      focusLevel == 0 ? 0.4 : this.p5.map(focusLevel, 0, 1, 1, 5);
    for (let y = 0; y < numY; y++) {
      const yPos = minY + offsets[y];

      if (yPos > maxY || yPos < minY) {
        offsets[y] = side == 'top' ? this.p5.height / 2 : 0;
      } else {
        offsets[y] += side == 'top' ? -scrollSpeed : scrollSpeed;
      }

      this.p5.line(0, yPos, this.p5.width, yPos);

      for (let x = 0; x < numX; x++) {
        const xMult = (x * this.p5.width) / numX;
        const skewAmt = this.p5.height * 2;
        const offset = this.p5.map(xMult, 0, this.p5.width, -skewAmt, skewAmt);
        if (side == 'top') {
          this.p5.line(xMult + offset, minY, xMult, maxY);
        } else {
          this.p5.line(xMult, minY, xMult + offset, maxY);
        }
      }
    }
  };

  drawFill = () => {
    const h = 360;

    const cFull = this.p5.color(2);
    const cOpacity = this.p5.color(2, 2);
    const halfH = this.p5.height / 2;
    const _y = halfH - h;
    const _height = h * 2;

    for (let lineY1 = halfH - h; lineY1 <= halfH; lineY1++) {
      const amt = this.p5.map(lineY1, halfH - h, halfH, 0, 1);
      const newc = this.p5.lerpColor(cOpacity, cFull, amt);
      this.p5.stroke(newc);
      this.p5.strokeWeight(1);
      this.p5.line(0, lineY1, this.p5.width, lineY1);
    }

    for (let lineY = halfH; lineY <= _y + _height; lineY++) {
      const amt = this.p5.map(lineY, halfH, _y + _height, 0, 1);
      const newc = this.p5.lerpColor(cFull, cOpacity, amt);
      this.p5.stroke(newc);
      this.p5.strokeWeight(1);
      this.p5.line(0, lineY, this.p5.width, lineY);
    }
  };
}

export default VaporGrid;
