import { useMutation, UseMutationResult } from 'react-query';

import { supabase } from './client';
import { Table } from '../types/db-models';

export interface ScoreReturnType {
  created_at: string;
  difficulty: string;
  event_id: number;
  id: number;
  mode: string;
  name: string;
  score: number;
}

const completePour = async (
  id: number,
  score: number,
  name?: string
): Promise<boolean> => {
  const { error } = await supabase
    .from(Table.POURS)
    .update({
      completed: true,
      name: !!name ? name : '???',
      score,
      claimed: !!name,
    })
    .eq('id', id);
  if (error) throw new Error(error.message);
  return true;
};

interface SubmitScoreParams {
  id: number;
  score: number;
  name?: string;
}

const useSubmitScore = (
  id: number,
  score: number,
  name?: string
): UseMutationResult<boolean, Error, SubmitScoreParams> => {
  return useMutation('complete-pour', () => completePour(id, score, name), {
    onSuccess: () => console.log('successfully wrote score'),
  });
};

export default useSubmitScore;
