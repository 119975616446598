import styled from 'styled-components';
import { motion } from 'framer-motion';

import { AppState } from '../lib/globalStateProvider';

import Button from './Button';
import { Mode } from './ModeSelector';
import TopScores from './scores/TopScores';
import { GameMode } from '../lib/types/game-types';
// import Selector from './Selector';

export type Difficulty = 'easy' | 'medium' | 'hard';

interface SelectModePaneProps {
  mode: Mode;
  handleStart: (m: AppState, s?: string) => void;
  cupDetected: boolean;
  id: string;
  unit?: string | null;
}

const SelectModePane = ({
  mode,
  handleStart,
  cupDetected,
  id,
  unit = null,
}: SelectModePaneProps): JSX.Element => {
  // const [difficulty] = useState<Difficulty>('easy');
  // const [difficulty, setDifficulty] = useState<Difficulty>('easy');

  const changeMode = () => {
    handleStart(mode.state, mode.skin);
  };

  return (
    <Pane
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, scale: 1.3 }}
      transition={{ duration: 2 }}
    >
      <PaneContent>
        <Section>
          <TextFancy>{mode.title}</TextFancy>
          <p style={{ fontSize: '20px', textAlign: 'center' }}>
            {mode.description}
          </p>
        </Section>
        <Section>
          {/* TODO: re-enable difficulty selection */}
          {/* {mode.hasDifficulty && (
            <Selector
              selected={difficulty}
              options={['easy', 'medium', 'hard']}
              setSelected={(d) => setDifficulty(d)}
            />
          )} */}
          {!cupDetected && (
            <p style={{ color: '#d56969', fontSize: '18px' }}>
              <b>Please place a cup in the holder to continue</b>
            </p>
          )}
          {mode.altButton ? (
            mode.altButton
          ) : (
            <Button onClick={changeMode}>START</Button>
          )}
          <>
            {mode.hasScoring && (
              // TODO: need to pass in actual account ID at some point
              <TopScores
                unit={unit}
                mode={mode.type as GameMode}
                skin={mode.skin}
                accountId={id}
              />
            )}
          </>
        </Section>
      </PaneContent>
    </Pane>
  );
};

const TextFancy = styled.div`
  font-family: 'habana deco ml', sans-serif;
  color: ${(p) => p.theme.colors.primary};
  font-size: max(15vh, 15vw);
  user-select: none;
  /* -webkit-text-stroke: 3px #fff;
  filter: drop-shadow(0 0 5px ${(p) => p.theme.colors.primary}); */
`;

export default SelectModePane;

const Pane = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  touch-action: none;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;

const PaneContent = styled.div`
  background: linear-gradient(
      180deg,
      rgba(67, 64, 83, 0.75) 0%,
      rgba(67, 64, 83, 0.67) 100%
    ),
    url('/images/grain.svg');

  backdrop-filter: blur(6px);

  border-radius: 32px;
  border: 2px solid ${(p) => p.theme.colors.paneBorder};

  box-shadow: 0 0 40px 10px ${(p) => p.theme.colors.paneShadow};

  padding: 64px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  min-width: 65vw;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;

  gap: 2px;
`;
