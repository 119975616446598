import { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useDrag } from '@use-gesture/react';
import { useSprings, animated } from '@react-spring/web';
import { clamp } from 'lodash';
import { motion } from 'framer-motion';

import {
  AppState,
  SelectableState,
  useGlobalState,
} from '../lib/globalStateProvider';
import SelectModePane from './SelectModePane';
import LongPressDetector from './LongPressDetector';
import BackgroundVideo from './BackgroundVideo';
import { ButtonWrapper } from './pages/ButtonMode';
import BrandLogo from './icons/BrandLogo';
import useModeChanger from '../lib/useModeChanger';
import { PourModes } from '../lib/types/game-types';

export interface Mode {
  type: PourModes;
  title: string;
  state: SelectableState;
  description: string;
  icon: string | null;
  hasDifficulty: boolean;
  altButton?: JSX.Element;
  hasScoring?: boolean;
  skin?: string;
}

const Modes: Mode[] = [
  {
    type: 'Mind',
    title: 'Mind',
    state: AppState.Mind,
    description: 'Focus your mind to pour the perfect pint.',
    icon: null,
    hasDifficulty: true,
    hasScoring: true,
  },
  {
    type: 'Body',
    title: 'Body',
    state: AppState.Body,
    description: 'Move yourself to control the flow.',
    icon: null,
    hasDifficulty: true,
    hasScoring: true,
  },
  {
    type: 'Race',
    title: 'Drive',
    state: AppState.Race,
    description: 'Race a car to the finish line.',
    icon: null,
    hasDifficulty: false,
    hasScoring: true,
    skin: 'road',
  },
  {
    type: 'Race',
    title: 'Space',
    state: AppState.Race,
    description: 'Race a spaceship to the finish line.',
    icon: null,
    hasDifficulty: false,
    hasScoring: true,
    skin: 'space',
  },
  {
    type: 'Button',
    title: 'Pour',
    state: AppState.Button,
    description: 'Pour a perfect pint with the touch of a button.',
    icon: null,
    hasDifficulty: false,
    altButton: <ButtonWrapper />,
    hasScoring: false,
  },
];

const ModeSelector = (): JSX.Element => {
  const { defaultMode, defaultSkin, setDefaultSkin, setDefaultMode } =
    useGlobalState();
  const defaultIndex = Modes.findIndex(
    (m) => m.state === defaultMode && (m.skin ?? '') === defaultSkin
  );
  const index = useRef(defaultIndex >= 0 ? defaultIndex : 0);
  // TODO: listen for resize?
  const width = window.innerWidth;
  const { cupDetected, account } = useGlobalState();
  const { changeMode } = useModeChanger();

  const switchMode = (newMode: AppState, skin?: string) => {
    if (Modes.find((m) => m.state === newMode)) {
      setDefaultMode(newMode);
      setDefaultSkin(skin ?? '');
    }
    changeMode(newMode, skin);
  };

  const [animProps, api] = useSprings(Modes.length, (i) => ({
    x: i * width,
    scale: 1,
    display: 'block',
  }));

  const updateSelector = useCallback(
    (active: boolean, mx: number) => {
      api.start((i) => {
        let display = 'block';
        if (i < index.current - 1 || i > index.current + 1) {
          display = 'none';
        }
        const x = (i - index.current) * width + (active ? mx : 0);
        const scale = active ? 1 - Math.abs(mx) / width / 2 : 1;
        return { x, scale, display };
      });
    },
    [api]
  );

  const bind = useDrag(
    ({ active, movement: [mx], direction: [xDir], cancel }) => {
      if (active && Math.abs(mx) > width / 2) {
        index.current = clamp(
          index.current + (xDir > 0 ? -1 : 1),
          0,
          Modes.length - 1
        );
        cancel();
      }
      updateSelector(active, mx);
    }
  );

  useEffect(() => {
    updateSelector(false, 0);
  }, [updateSelector]);

  return (
    <Container>
      {animProps.map(({ x, display, scale }, i) => (
        <Page {...bind()} key={i} style={{ display, x, scale }}>
          <SelectModePane
            cupDetected={cupDetected}
            handleStart={switchMode}
            mode={Modes[i]}
            id={account ? account.id : ''}
          />
        </Page>
      ))}
      <LongPressDetector callback={() => switchMode(AppState.Config)} />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 3 }}
        style={{
          position: 'fixed',
          bottom: '24px',
          right: 0,
          left: 0,
          width: '432px',
          margin: '0 auto',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <BrandLogo />
      </motion.div>
      <BackgroundVideo />
    </Container>
  );
};

export default ModeSelector;

// TODO: this doesn't need to be 100% height, the parent can place it in the center
const Container = styled.div`
  z-index: 1;
  width: 100%;
  height: 100%;

  position: relative;

  background-color: ${(p) => p.theme.colors.bg};

  overflow: hidden;
`;

const Page = styled(animated.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  touch-action: none;
  padding: 64px;
  z-index: 1;
`;
