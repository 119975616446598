import { Sketch, SketchProps } from '@p5-wrapper/react';

import { Catcher } from './catcher';
import { Dropper } from './dropper';
import VaporGrid from '../shared/vaporGrid';
import { Image, Font } from 'p5';
import TopDisplay from '../shared/topDisplay';

export interface GameProps extends SketchProps {
  handPos: HandPos;
  running: boolean;
  topColor: string;
  bodyLeftImageUrl?: string | null;
  bodyRightImageUrl?: string | null;
  bodyImageTint?: boolean;
  dropperLower?: number;
  dropperHigher?: number;
  _pourTime: number;
  _timeRemaining: number;
  _practiceTime: number;
  setScore: (score: number) => void;
}

export interface DropperCounts {
  numCreated: number;
  numCaught: number;
}

type XYCoord = { x: number; y: number };

export interface HandPos {
  left: XYCoord;
  right: XYCoord;
}

// TODO: put in center of screen
export const initialHandPos: HandPos = {
  left: { x: 0, y: 0 },
  right: { x: 0, y: 0 },
};

export const sketch: Sketch<GameProps> = (p5) => {
  let leftHand: Catcher;
  let rightHand: Catcher;
  const droppers: Dropper[] = [];
  let handPos: HandPos = {
    left: { ...initialHandPos.left },
    right: { ...initialHandPos.right },
  };
  let isRunning = false;
  let numDroppersCreated = 0;
  let numCaught = 0;
  let TYPEFACE: Font;
  let timeRemaining = 0;
  let pourTime = 0;
  let practiceTime = 0;
  let updateScore: (score: number) => void;
  let leftImage: Image, rightImage: Image;
  let lastTimeDropped = 0;
  let practiceImg: Image;
  let grid: VaporGrid<GameProps>;
  let topDisplay: TopDisplay<GameProps>;
  let topColor: string;

  let dropperLower = 1;
  let dropperHigher = 7;

  let dropperLeftImage: Image;
  let dropperLeftImageUrl: string;
  let dropperRightImage: Image;
  let dropperRightImageUrl: string;

  let tintDropperImage = false;

  const addDroppers = (numToCreate: number, inPractice = false) => {
    if (inPractice) {
      Array.from({ length: numToCreate }, () =>
        droppers.push(new Dropper(p5, 1, p5.random(0, 20)))
      );
    } else {
      const speedMultiplier = p5.map(timeRemaining, pourTime, 0, 1, 3);
      Array.from({ length: numToCreate }, () =>
        droppers.push(new Dropper(p5, speedMultiplier, p5.random(0, 60)))
      );
    }
  };

  const calculateScore = (numCreated: number, numCaught: number): number => {
    const score = numCreated > 0 ? (numCaught / numCreated) * 100.0 : 100.0;
    return score;
  };

  p5.preload = () => {
    TYPEFACE = p5.loadFont('assets/ShareTechMono-Regular.ttf');
    leftImage = p5.loadImage('assets/lh-32.png');
    rightImage = p5.loadImage('assets/rh-32.png');
    practiceImg = p5.loadImage('assets/practice.png');
    if (dropperLeftImageUrl) {
      dropperLeftImage = p5.loadImage(dropperLeftImageUrl);
    }
    if (dropperRightImageUrl) {
      dropperRightImage = p5.loadImage(dropperRightImageUrl);
    }
  };

  p5.updateWithProps = (props) => {
    if (props.handPos && handPos !== props.handPos) {
      handPos = props.handPos;
    }

    if (
      typeof props.bodyLeftImageUrl == 'string' &&
      props.bodyLeftImageUrl != ''
    ) {
      if (props.bodyLeftImageUrl != dropperLeftImageUrl) {
        dropperLeftImageUrl = props.bodyLeftImageUrl;
        p5.loadImage(dropperLeftImageUrl, (image) => {
          dropperLeftImage = image;
        });
      }
    }

    if (
      typeof props.bodyRightImageUrl == 'string' &&
      props.bodyRightImageUrl != ''
    ) {
      if (props.bodyRightImageUrl != dropperRightImageUrl) {
        dropperRightImageUrl = props.bodyRightImageUrl;
        p5.loadImage(dropperRightImageUrl, (image) => {
          dropperRightImage = image;
        });
      }
    }

    if (typeof props.bodyImageTint === 'boolean') {
      tintDropperImage = props.bodyImageTint;
    }

    if (typeof props.dropperLower === 'number') {
      dropperLower = props.dropperLower;
    }

    if (typeof props.dropperHigher === 'number') {
      dropperHigher = props.dropperHigher;
    }

    if (typeof props.running === 'boolean') {
      isRunning = props.running;
    }

    if (typeof props._pourTime === 'number') {
      pourTime = props._pourTime;
    }

    if (typeof props._practiceTime === 'number') {
      practiceTime = props._practiceTime;
    }

    if (typeof props._timeRemaining === 'number') {
      timeRemaining = props._timeRemaining;
    }

    if (typeof props.topColor === 'string') {
      topColor = props.topColor;
    }

    if (typeof props.setScore === 'function') {
      updateScore = props.setScore;
    }
  };

  p5.setup = () => {
    console.log('DROPPER SETUP');
    p5.createCanvas(window.innerWidth, window.innerHeight);
    p5.pixelDensity(1);
    p5.textFont(TYPEFACE);
    p5.frameRate(60);

    leftHand = new Catcher(p5, 'l', leftImage);
    rightHand = new Catcher(p5, 'r', rightImage);

    grid = new VaporGrid<GameProps>(p5);
    topDisplay = new TopDisplay<GameProps>(p5, topColor);

    addDroppers(1); //this could be removed
  };

  p5.draw = () => {
    const inPractice = timeRemaining - pourTime >= 0;

    const caughtRatio =
      inPractice || numDroppersCreated == 0
        ? 0
        : numCaught / numDroppersCreated;

    const mainOpacity = p5.map(caughtRatio, 0, 1, 150, 20);
    p5.background(2, 2, 2, mainOpacity);
    grid.draw(caughtRatio);

    const currScore = inPractice
      ? 0
      : calculateScore(numDroppersCreated, numCaught);
    if (timeRemaining <= 0) {
      if (isRunning) updateScore(currScore);
      isRunning = false;
      // GAME OVER
      return;
    }

    if (isRunning) {
      if (timeRemaining !== lastTimeDropped) {
        if (timeRemaining >= 1) {
          const created = inPractice
            ? p5.ceil(p5.random(0, 2)) // create 1 or 2 droppers during practice
            : p5.floor(p5.random(dropperLower, dropperHigher)); // create more droppers during game
          if (!inPractice) {
            console.log(
              `Creating between ${dropperLower} and ${dropperHigher} droppers (${created})`
            );
          }
          addDroppers(created, inPractice);
          if (!inPractice) numDroppersCreated += created; // don't count towards score during practice
        }
        lastTimeDropped = timeRemaining;
      }

      if (p5.frameCount % 60 == 0 && !inPractice) {
        console.log('curr fr: ', p5.frameRate());
        updateScore(currScore);
      }

      leftHand.update(handPos.left.x, handPos.left.y);
      rightHand.update(handPos.right.x, handPos.right.y);
      leftHand.draw();
      rightHand.draw();

      for (let i = 0; i < droppers.length; i++) {
        droppers[i].draw(
          droppers[i].side === 'l' ? dropperLeftImage : dropperRightImage,
          tintDropperImage
        );
        if (leftHand.intersects(droppers[i])) {
          const caughtCorrectly = droppers[i].caught('l');
          if (!inPractice) numCaught += caughtCorrectly ? 1 : 0;
        } else if (rightHand.intersects(droppers[i])) {
          const caughtCorrectly = droppers[i].caught('r');
          if (!inPractice) numCaught += caughtCorrectly ? 1 : 0;
        }
      }

      droppers.forEach((d, i) => {
        if (d.hasReachedBottom() || d.dropletsGone) {
          droppers.splice(i, 1);
        }
      });

      topDisplay.draw(
        inPractice ? pourTime : timeRemaining,
        inPractice ? 0 : calculateScore(numDroppersCreated, numCaught),
        caughtRatio * 100
      );

      if (inPractice) {
        p5.push();
        p5.translate(0, -150);
        p5.fill(255);
        const w = 300;
        const h = 40;
        p5.image(practiceImg, p5.width / 2 - w / 2, p5.height / 2 - h * 2, w);
        const practiceProgress = p5.map(
          timeRemaining - pourTime,
          practiceTime,
          0,
          0,
          w
        );

        p5.noFill();
        p5.stroke(255);
        p5.rect(p5.width / 2 - w / 2, p5.height / 2, 300, h);
        p5.noStroke();
        p5.fill(255);
        p5.rect(p5.width / 2 - w / 2, p5.height / 2, practiceProgress, h);

        p5.pop();
      }
    } else {
      if (p5.frameCount % 64 == 0) {
        console.log('NO LOOP');
      }
      // p5.noLoop();
    }
  };
};
