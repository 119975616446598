import styled from 'styled-components';
import Button from './Button';

const ConnectionErrorOverlay = ({
  setRobotDemoMode,
}: {
  setRobotDemoMode: (m: boolean) => void;
}) => {
  return (
    <Container>
      <Inner>
        <div style={{ textAlign: 'center' }}>
          {/* TODO: show an image of the desktop icon */}
          <h1>There was an issue making the hardware connection</h1>
          <p>
            Please check that the Arcade Strange Desktop app is running and try
            again
          </p>
        </div>
        <Button
          style={{ maxWidth: '256px' }}
          onClick={() => window.location.reload()}
        >
          Retry
        </Button>
        <Button
          style={{ maxWidth: '256px' }}
          onClick={() => setRobotDemoMode(true)}
        >
          Demo Mode
        </Button>
      </Inner>
    </Container>
  );
};

export default ConnectionErrorOverlay;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  place-content: center;
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;
