import { createContext, useContext, useRef, useEffect, useState } from 'react';

import { AppState, useGlobalState } from '../globalStateProvider';
import useInterval from '../useInterval';

interface WSProviderProps {
  children: JSX.Element;
  url: string;
  protocols?: string | string[];
  onOpen?: (ev: Event) => void;
}

enum WebSocketState {
  CONNECTING = 0,
  OPEN = 1,
  CLOSING = 2,
  CLOSED = 3,
  OK = 1 | 0,
}

const connectionId = 'd34i-ba8n';

export interface ConnectionStatusData {
  robot_connected: boolean;
  headset_connection: number;
}

export type MessageType =
  | 'connection'
  | 'change_mode'
  | 'change_headset'
  | 'headset_list'
  | 'mode_changed'
  | 'start_pour'
  | 'pour_started'
  | 'pour_progress'
  | 'mind_reading'
  | 'has_cup'
  | 'tap'
  | 'connection_status';

export interface UpdateAngles {
  type: 'update_angle';
  data: {
    glass_angle?: number;
    tap_angle?: number;
  };
}

export interface ChangeDefaultAngles {
  type: 'change_default_angles';
  data: {
    glass_best_angle: number;
    glass_worst_angle: number;
    tap_off_angle: number;
    tap_on_angle: number;
  };
}

export interface HeadsetList {
  type: 'headset_list';
  data: string[];
}

export interface InterruptPourChanged {
  type: 'interrupt_pour_changed' | 'interrupt_pour';
  data: boolean;
}

const RETRY_LIMIT = 5;

// TODO: improve these types, if we know what message type then we should know the shape of the data
interface WSMessageData {
  type: MessageType;
  data: string | number;
}

type MessageData =
  | WSMessageData
  | UpdateAngles
  | ChangeDefaultAngles
  | HeadsetList
  | InterruptPourChanged;

interface WSContext {
  ws: WebSocket | undefined;
  tryConnection: (connectionId: string) => void;
  sendWsMessage: (msg: MessageData) => void;
  lastMessage: MessageData;
}

export const WebSocketContext = createContext<WSContext>({} as WSContext);

export const useWebSocket = (): WSContext => useContext(WebSocketContext);

export const WebSocketProvider = ({
  url,
  onOpen,
  children,
}: WSProviderProps): JSX.Element => {
  const {
    setConnectionStatus,
    hasRobotConnection,
    setCurrentMode,
    setCupDetected,
    cupDetected,
    hasConnectionError,
    setHasConnectionError,
    setConnectionStatusData,
    setHeadsetList,
    connectionStatus,
    pourParams,
    setInterruptPour,
  } = useGlobalState();

  const ws = useRef<WebSocket>();
  const onOpenRef = useRef<(ev: Event) => void>();
  const [hasBaseConnection, setHasBaseConnection] = useState(false);
  const [retryAttempts, setRetyAttempts] = useState(0);

  useEffect(() => {
    console.log({ retryAttempts });
  }, [retryAttempts]);

  // TODO: use ref for this to avoid re-renders
  const [lastMessage, setLastMessage] = useState<MessageData>(
    {} as WSMessageData
  );
  // const lastMessage = useRef<WSMessageData>({} as WSMessageData);

  const sendWsMessage = (message: MessageData) => {
    if (ws.current?.readyState !== WebSocketState.OK) {
      setHasConnectionError(true);
    }
    const jsonMessage = JSON.stringify(message);
    try {
      ws.current?.send(jsonMessage);
    } catch (error) {
      console.error('error sending msg: ', error);
      setHasConnectionError(true);
      console.log(hasConnectionError);
    }
  };

  useInterval(
    () => {
      if (retryAttempts <= RETRY_LIMIT) {
        console.log({ bc: hasBaseConnection });
        setHasBaseConnection(ws.current?.readyState === WebSocketState.OK);
        const rs =
          typeof ws.current?.readyState === 'number'
            ? WebSocketState[ws.current?.readyState]
            : undefined;

        console.log({ readyState: rs });

        try {
          ws.current = new WebSocket(url);
        } catch (error) {
          console.error(error);
        }

        setRetyAttempts(retryAttempts + 1);
      } else {
        setHasConnectionError(true);
      }
    },
    hasBaseConnection || hasConnectionError ? null : 3000
  );
  onOpenRef.current = onOpen;

  useEffect(() => {
    console.log(`CURRENT STATE: ${ws.current?.readyState}`);
    initWs();
  }, [ws.current?.readyState]);

  useEffect(() => {
    if (connectionStatus) {
      sendWsMessage({
        type: 'change_default_angles',
        data: {
          glass_best_angle: pourParams.glassBestAngle,
          glass_worst_angle: pourParams.glassWorstAngle,
          tap_off_angle: pourParams.tapOffAngle,
          tap_on_angle: pourParams.tapOnAngle,
        },
      });
    }
  }, [
    connectionStatus,
    pourParams.glassBestAngle,
    pourParams.glassWorstAngle,
    pourParams.tapOffAngle,
    pourParams.tapOnAngle,
  ]);

  useEffect(() => {
    if (ws.current) {
      if (hasBaseConnection && !hasRobotConnection) {
        tryBackendConnection(connectionId);
      }
    }
  }, [hasBaseConnection, hasRobotConnection]);

  useEffect(() => {
    return () => ws?.current?.close();
  }, []);

  const initWs = () => {
    console.log('INIT WS');
    console.log({ hasBaseConnection, connectionId });
    if (ws.current) {
      ws.current.onopen = () => {
        console.log('on open...');
        tryBackendConnection(connectionId);
      };
      ws.current.onmessage = (e: MessageEvent) => {
        let message;

        try {
          message = JSON.parse(e.data);
        } catch (error) {
          message = JSON.stringify(error);
        }
        // TODO: use refs here to avoid re-rendering whole app
        setLastMessage(message);
      };
      ws.current.onerror = (e) => {
        throw new Error(JSON.stringify(e));
      };
    }
  };

  useEffect(() => {
    if (lastMessage.type == 'connection') {
      console.log('CONNECTION');
      setConnectionStatus(true);
    }

    if (lastMessage.type == 'mode_changed') {
      setCurrentMode(lastMessage.data as AppState);
    }

    if (lastMessage.type == 'headset_list') {
      setHeadsetList(lastMessage.data as string[]);
    }

    if (lastMessage.type == 'interrupt_pour_changed') {
      setInterruptPour(lastMessage.data as boolean);
    }

    if (lastMessage.type === 'has_cup') {
      const hasCup = parseInt(lastMessage.data as string) === 0 ? false : true;
      console.log({ cupDetected, hasCup, raw: lastMessage.data });
      if (cupDetected !== hasCup) setCupDetected(hasCup);
    }

    if (lastMessage.type === 'connection_status') {
      const data = JSON.parse(
        lastMessage.data as string
      ) as ConnectionStatusData;
      setConnectionStatusData(data);
    }
  }, [lastMessage]);

  const tryBackendConnection = (connectionId: string) => {
    const msg = JSON.stringify({
      type: 'connection',
      connectionId,
    });

    console.log(ws.current);

    if (ws.current !== undefined) {
      console.log(ws);
      ws.current.send(msg);
      console.log('send message: ', msg);
    }
  };

  return (
    <WebSocketContext.Provider
      value={{
        ws: ws.current,
        tryConnection: tryBackendConnection,
        sendWsMessage,
        lastMessage,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};
