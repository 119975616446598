import { useAnimationFrame } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { ReactP5Wrapper } from '@p5-wrapper/react';
import styled, { useTheme } from 'styled-components';

import useCreatePour from '../../lib/db/useCreatePour';
import { AppState, useGlobalState } from '../../lib/globalStateProvider';
import useHandEstimation from '../../lib/useHandsEstimation';
import useInterval from '../../lib/useInterval';
import usePourController from '../../lib/usePourController';
import { calculateFinalScore, mapRange } from '../../lib/utils';
import ExitButton from '../icons/ExitButton';

import { sketch } from '../games/dropper/main';
import PostGameDialog from '../PostGameDialog';
import PreGameDialog from '../PreGameDialog';
import Countdown from '../Countdown';
import useModeChanger from '../../lib/useModeChanger';
import { usePersonalDetails } from '../../lib/usePersonalDetails';

// TODO: ensure posenet is fully loaded before starting (pass isReady prop back from useHandsEstiamtion?)

// TODO: change to generic GameModeProps
interface BodyModeProps {
  accountId: string;
  // difficulty: Difficulty
}

const HANDPOS_THROTTLE = 100;

const BodyMode = ({ accountId }: BodyModeProps): JSX.Element => {
  const { confirmed, name } = usePersonalDetails();
  const { pourParams, currentEvent } = useGlobalState();
  const { changeMode } = useModeChanger();
  const theme = useTheme();
  const webcamRef = useRef<HTMLVideoElement | null>(null);
  const [isRunning, setIsRunning] = useState(false);
  const scoreArr = useRef<number[]>([]);
  const [lastHandPosCalc, setLastHandPosCalc] = useState(0);
  const score = useRef(-1);
  const {
    handPos,
    init,
    update: updateHandsEstimation,
    initialised,
  } = useHandEstimation({
    webcamRef,
  });

  if (currentEvent == null) {
    throw new Error('No event selected');
  }

  const pourDbEntry = useCreatePour(
    accountId,
    currentEvent.id,
    'Body',
    '',
    'easy'
  );

  const [showCountdown, setShowCountdown] = useState(false);
  const [showPreGameDialog, setShowPreGameDialog] = useState(true);
  const [showPostGameDialog, setShowPostGameDialog] = useState(false);

  const onGameFinish = () => {
    console.log('onGameFinish');
    setIsRunning(false);
    setShowPostGameDialog(true);
  };

  const updateScore = (_score: number) => {
    score.current = _score;
    scoreArr.current.push(_score);
  };

  const { start, stop, setAngles, secondsRemaining } =
    usePourController(onGameFinish);

  const handleStart = () => {
    if (isRunning) console.log('pause()');
    else {
      pourDbEntry.mutate({
        id: accountId,
        eventId: currentEvent.id,
        m: 'Body',
        d: 'easy',
        ...(confirmed
          ? {
              name,
              claimed: true,
            }
          : {}),
      });
      setShowPreGameDialog(false);
      setShowCountdown(true);
    }
  };

  const goHome = () => changeMode(AppState.Home);

  const onCountdownComplete = () => {
    start();
    setShowCountdown(false);
    setIsRunning(true);
  };

  useEffect(() => {
    console.log({ initialised, isRunning });
  }, [initialised, isRunning]);

  const initialising = useRef(false);

  useEffect(() => {
    if (webcamRef.current !== null && !initialised && !initialising.current) {
      console.log('calling init');
      init();
      initialising.current = true;
    }
  }, [webcamRef.current]);

  useAnimationFrame((timestamp: number) => {
    if (
      initialised &&
      isRunning &&
      timestamp - lastHandPosCalc > HANDPOS_THROTTLE
    ) {
      updateHandsEstimation();
      setLastHandPosCalc(timestamp);
    }
  });

  const isLoading = !initialised && webcamRef.current === null;

  // Game Loop
  useInterval(
    () => {
      const currScore = score.current;
      const tapAngle = mapRange(
        currScore,
        0.0,
        100,
        pourParams.tapOffAngle,
        pourParams.tapOnAngle
      );
      const glassAngle = mapRange(
        currScore,
        0.0,
        100,
        pourParams.glassWorstAngle,
        pourParams.glassBestAngle
      );

      setAngles(tapAngle, glassAngle); // do this inside pour controller?
    },
    initialised && isRunning ? 100 : null
  );

  const exitGame = () => {
    stop();
    goHome();
  };

  return (
    <Container>
      {showPreGameDialog && (
        <PreGameDialog
          callback={handleStart}
          isLoading={isLoading}
          mode="Body"
          exit={goHome}
        />
      )}
      {showPostGameDialog && pourDbEntry.isSuccess && (
        <PostGameDialog
          pourId={pourDbEntry.data}
          mode={'Body'}
          score={calculateFinalScore(scoreArr.current)}
          callback={goHome}
        />
      )}
      {showCountdown && (
        <Countdown
          target={3}
          started={showCountdown}
          onEndCallback={onCountdownComplete}
        />
      )}
      <div
        style={{
          position: 'absolute',
          zIndex: 3,
          height: '100%',
          width: '100%',
        }}
      >
        <WebcamViewer ref={webcamRef} />
      </div>
      <SketchContainer>
        {initialised && (
          <ReactP5Wrapper
            sketch={sketch}
            _pourTime={pourParams.pourDurationSeconds}
            _practiceTime={pourParams.gamePracticeDuration}
            _timeRemaining={secondsRemaining}
            handPos={handPos}
            topColor={theme.colors.secondary}
            running={isRunning}
            setScore={updateScore}
            bodyLeftImageUrl={currentEvent.body_left_image_path}
            bodyRightImageUrl={currentEvent.body_right_image_path}
            bodyImageTint={currentEvent.body_image_tint}
            dropperLower={Math.min(
              pourParams.minDroppers,
              pourParams.maxDroppers
            )}
            dropperHigher={Math.max(
              pourParams.minDroppers,
              pourParams.maxDroppers
            )}
            // createDroppers={createDroppers}
            // onDroppersCreated={onDroppersCreated}
            // updateDroppersCount={updateDroppersCount}
          />
        )}
      </SketchContainer>
      <ExitButton onClick={exitGame}>&times;</ExitButton>
    </Container>
  );
};

export default BodyMode;

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const SketchContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
`;

const WebcamViewer = styled.video`
  transform: rotateY(180deg);
  position: absolute;
  width: 320px;
  height: 240px;
  opacity: 0.75;
  bottom: 24px;
  margin: 0 auto;
  left: 0;
  right: 0;
  border-radius: 32px;
`;
