import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

if (supabaseUrl == undefined) {
  throw new Error('Supabase URL not provided');
}

if (supabaseAnonKey == undefined) {
  throw new Error('Supabase anonymous key not provided');
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey);
