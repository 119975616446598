import { useCallback, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useTimer } from 'react-timer-hook';
import { useGlobalState } from './globalStateProvider';

import { useWebSocket } from './sockets/ws-provider';
import useInterval from './useInterval';

interface UsePourController {
  pourTime: number;
  hasFinished: boolean;
  start: (initialTap?: number, initialGlass?: number) => void;
  secondsRemaining: number;
  setAngles: (tap: number, glass: number) => void;
  isPouring: boolean;
  stop: () => void;
  inPractice: boolean;
  updateGlassAngle: (angle: number) => void;
  updateTapAngle: (angle: number) => void;
}

interface ServoAngles {
  glass: number;
  tap: number;
}

const usePourController = (
  onCompleteCallback?: () => void,
  usePractice = true
): UsePourController => {
  const { sendWsMessage } = useWebSocket();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { cupDetected, setCupDetected, pourParams } = useGlobalState();
  // TODO: need to get initial angles from state
  const servoAngles = useRef<ServoAngles>({
    glass: pourParams.glassWorstAngle,
    tap: pourParams.tapOffAngle,
  });
  const [hasFinished, setHasFinished] = useState(false);
  const time = new Date();
  time.setSeconds(
    time.getSeconds() +
      pourParams.pourDurationSeconds +
      (usePractice ? pourParams.gamePracticeDuration : 0)
  );

  const updateAngles = useCallback(
    (tagAngle: number, glassAngle: number) => {
      sendWsMessage({
        type: 'update_angle',
        data: {
          tap_angle: tagAngle,
          glass_angle: glassAngle,
        },
      });
    },
    [sendWsMessage]
  );

  const updateGlassAngle = useCallback(
    (glassAngle: number) => {
      console.log('Update glass angle: ', glassAngle);
      setAngles(servoAngles.current.tap, glassAngle);
      sendWsMessage({
        type: 'update_angle',
        data: {
          glass_angle: glassAngle,
        },
      });
    },
    [sendWsMessage]
  );

  const updateTapAngle = useCallback(
    (tapAngle: number) => {
      console.log('Update tap angle: ', tapAngle);
      setAngles(tapAngle, servoAngles.current.glass);
      sendWsMessage({
        type: 'update_angle',
        data: {
          tap_angle: tapAngle,
        },
      });
    },
    [sendWsMessage]
  );

  const onComplete = () => {
    console.log('TIMER HAS COMPLETE');
    setHasFinished(true);
    // setCupDetected(false);

    if (onCompleteCallback) onCompleteCallback();

    updateAngles(pourParams.tapOffAngle, pourParams.glassWorstAngle);

    // TODO: have state managed in web app and pass through websocekt on change
    // sendWsMessage({ type: 'change_mode', data: AppState.Home });
  };

  const onStart = (initialTap?: number, initialGlass?: number) => {
    time.setSeconds(new Date().getSeconds() + pourParams.pourDurationSeconds);
    setAngles(
      initialTap ?? pourParams.tapOffAngle,
      initialGlass ?? pourParams.glassWorstAngle
    );

    if (!cupDetected) {
      toast.error('Please place a glass in the holder', {
        style: { fontSize: '32px' },
      });
      updateAngles(pourParams.tapOffAngle, pourParams.glassWorstAngle);
    } else {
      console.log('starting timer...');
      start();
    }
  };

  const stopPour = () => {
    pause();
    updateAngles(pourParams.tapOffAngle, pourParams.glassWorstAngle);
  };

  // useEffect(() => {
  //   if (cupDetected) {
  //     if (!isRunning) {
  //       setTimeout(() => {
  //         toast('Glass detected, starting pour', {
  //           style: { fontSize: '32px' },
  //           icon: '🍺',
  //         });
  //       }, 500);
  //     }
  //   }
  // }, [cupDetected]);

  const {
    start,
    seconds: secondsRemaining,
    isRunning,
    pause,
  } = useTimer({
    expiryTimestamp: time,
    onExpire: onComplete,
    autoStart: false,
  });

  const previousTime = useRef(-1);

  useInterval(
    () => {
      const inPractice = secondsRemaining - pourParams.pourDurationSeconds >= 0;
      if (inPractice) {
        // console.log('in practice, dont set servos');
        return;
      }

      if (previousTime.current != secondsRemaining) {
        if (secondsRemaining <= 3) {
          // ease back tap and glass for end of pour
          updateAngles(pourParams.tapOnAngle, pourParams.glassWorstAngle);

          console.log({
            secondsRemaining,
            g: pourParams.glassWorstAngle,
            t: pourParams.tapOnAngle,
          });
        } else {
          updateAngles(pourParams.tapOnAngle, servoAngles.current.glass);

          // Per Seth's request, the tap should also open and close based on score
          // tap_angle: servoAngles.current.tap,
          console.log({
            secondsRemaining,
            g: servoAngles.current.glass,
            t: pourParams.tapOnAngle,
          });
        }
        previousTime.current = secondsRemaining;
      }
      if (secondsRemaining <= 0) {
        onComplete();
      }
    },
    isRunning ? 100 : null
  );

  const setAngles = (tap: number, glass: number) => {
    servoAngles.current.tap = tap;
    servoAngles.current.glass = glass;
  };

  return {
    pourTime: pourParams.pourDurationSeconds,
    hasFinished,
    start: onStart,
    secondsRemaining,
    setAngles,
    isPouring: isRunning,
    stop: stopPour,
    inPractice: secondsRemaining - pourParams.pourDurationSeconds >= 0,
    updateGlassAngle,
    updateTapAngle,
  };
};

export default usePourController;
