import styled from 'styled-components';

const ExitButton = styled.button`
  position: fixed;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 10vw;
  height: 10vw;
  font-size: 8vw;
  background-color: transparent;
  border: none;
  z-index: 10000;
  color: rgba(255, 255, 255, 0.2);
`;

export default ExitButton;
