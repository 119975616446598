import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  background-color: ${(p) => p.theme.colors.bg};
  color: ${(p) => p.theme.colors.primary};

  overflow: hidden;
`;

export const FullPageContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.75);

  display: grid;
  place-content: center;

  z-index: 5;
`;

export const Dialog = styled.div`
  border: 2px solid ${(p) => p.theme.colors.paneBorder};

  box-shadow: 0 0 40px 10px ${(p) => p.theme.colors.paneShadow};
  padding: 48px;

  background: linear-gradient(
      180deg,
      rgba(67, 64, 83, 0.35) 0%,
      rgba(67, 64, 83, 0.67) 100%
    ),
    url('/images/grain.svg');

  backdrop-filter: blur(8px);

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  border-radius: 32px;

  margin: 48px;

  text-align: center;

  min-width: 60vw;
`;
