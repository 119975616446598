import { P5CanvasInstance, SketchProps } from '@p5-wrapper/react';

class TopDisplay<Props extends SketchProps = SketchProps> {
  p5: P5CanvasInstance<Props>;
  fillColor: string;

  constructor(p5: P5CanvasInstance<Props>, fillColor = '#24fdf0') {
    this.p5 = p5;
    this.fillColor = fillColor;
  }

  draw(
    timeLeft: number,
    score: number,
    pct: number,
    performance = 'performance'
  ): void {
    const m = 20;
    const dm = m * 2;
    const hw = this.p5.width / 2;
    const col = this.p5.color(this.fillColor);
    this.p5.push();
    this.p5.fill(col);
    this.p5.textSize(60);
    this.p5.noStroke();
    this.p5.textAlign(this.p5.LEFT);
    this.p5.text(`${Math.round(timeLeft)}s`, 50, 90);
    this.p5.textAlign(this.p5.RIGHT);
    this.p5.text(score.toFixed(), this.p5.width - 50, 90);
    // this.p5.stroke(255);
    // this.p5.strokeWeight(1);
    this.p5.textSize(22);
    this.p5.textAlign(this.p5.RIGHT);
    this.p5.text('remaining', hw - 50, 46);
    this.p5.textAlign(this.p5.LEFT);
    this.p5.text(performance, hw + 50, 46);
    this.p5.pop();

    const maxW = hw * 2 - hw - 8;
    const fillAmt = this.p5.map(pct, 0, 100, 0, maxW);

    this.p5.stroke(255);
    this.p5.noFill();
    this.p5.push();
    this.p5.strokeWeight(2);
    this.p5.rect(hw / 2 + 4, dm + m + 12, maxW, 46);
    this.p5.noStroke();
    this.p5.fill(col);
    this.p5.rect(hw / 2 + 4, dm + m + 12, fillAmt, 46);
    this.p5.pop();

    this.p5.strokeWeight(2);
    this.p5.push();
    this.p5.beginShape();
    this.p5.vertex(dm, m);
    this.p5.vertex(hw - dm, m);
    this.p5.vertex(hw - m, dm);
    this.p5.vertex(hw - m, dm + m);
    this.p5.vertex(hw / 2, dm + m);
    this.p5.vertex(hw / 2 - m, dm * 3);
    this.p5.vertex(dm, dm * 3);
    this.p5.vertex(m, dm * 2.5);
    this.p5.vertex(m, dm);
    this.p5.endShape(this.p5.CLOSE);
    this.p5.pop();

    this.p5.push();
    this.p5.translate(hw * 2, 0);
    this.p5.scale(-1, 1);
    this.p5.beginShape();
    this.p5.vertex(dm, m);
    this.p5.vertex(hw - dm, m);
    this.p5.vertex(hw - m, dm);
    this.p5.vertex(hw - m, dm + m);
    this.p5.vertex(hw / 2, dm + m);
    this.p5.vertex(hw / 2 - m, dm * 3);
    this.p5.vertex(dm, dm * 3);
    this.p5.vertex(m, dm * 2.5);
    this.p5.vertex(m, dm);
    this.p5.endShape(this.p5.CLOSE);

    this.p5.pop();
  }
}

export default TopDisplay;
