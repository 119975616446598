import { useCallback, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import styled from 'styled-components';
import { SimpleKeyboard } from 'react-simple-keyboard';

import {
  AppState,
  PersonalDetailEntry,
  PourParams,
  useGlobalState,
} from '../../lib/globalStateProvider';
import { useWebSocket } from '../../lib/sockets/ws-provider';

import Button, { ButtonContainer } from '../Button';
import BackIcon from '../icons/BackIcon';
import { Container } from '../Layout';
import usePourController from '../../lib/usePourController';
import { useLocalMindControl } from '../../lib/localMindControlProvider';
import useModeChanger from '../../lib/useModeChanger';
import Keyboard from '../Keyboard';

const Config = (): JSX.Element => {
  const {
    pourParams,
    setPourParams,
    toggleCursorVisibility,
    setCurrentEvent,
    setCurrentMode,
    headsetList,
    localHeadsetConnected,
    localHeadsetFocus,
    resetAttentionThresholds,
    resetDropperThresholds,
    interruptPour,
    mockMindControl,
    setMockMindControl,
    skipPreGamePersonalDetailEntry,
    setSkipPreGamePersonalDetailEntry,
    collectEmail,
    setCollectEmail,
    preGamePersonalDetailEntry,
    setPreGamePersonalDetailEntry,
    postGamePersonalDetailEntry,
    setPostGamePersonalDetailEntry,
    useSoftwareKeyboard,
    setUseSoftwareKeyboard,
  } = useGlobalState();
  const {
    connectToHeadset,
    disconnectFromHeadset,
    headsetBattery,
    headsetName,
    headsetOn,
    acceleration,
    calibrateMode,
    setCalibrateMode,
  } = useLocalMindControl();
  const [newParams, setNewParams] = useState<PourParams>(pourParams);
  const [keyboardVisible, setKeyboardVisible] = useState(false);
  const [currentFocusKey, setCurrentFocusKey] = useState<keyof PourParams>();
  const keyboard = useRef<SimpleKeyboard>();
  const { sendWsMessage } = useWebSocket();
  const pController = usePourController();
  const { changeMode } = useModeChanger();

  const back = () => changeMode(AppState.Home);

  const onConnectClick = useCallback(() => {
    if (!localHeadsetConnected) {
      void connectToHeadset();
    } else {
      disconnectFromHeadset();
    }
  }, [localHeadsetConnected]);

  useEffect(() => {
    setNewParams((p) => ({ ...p, minAttention: pourParams.minAttention }));
  }, [pourParams.minAttention]);

  useEffect(() => {
    setNewParams((p) => ({ ...p, maxAttention: pourParams.maxAttention }));
  }, [pourParams.maxAttention]);

  useEffect(() => {
    if (
      useSoftwareKeyboard &&
      keyboardVisible &&
      keyboard.current !== undefined &&
      currentFocusKey !== undefined
    ) {
      console.log('setInput', `${newParams[currentFocusKey]}`);
      keyboard.current.setInput(`${newParams[currentFocusKey]}`);
      console.log(keyboard.current.getInput(`pourParams${currentFocusKey}`));
    }
  }, [useSoftwareKeyboard, newParams, currentFocusKey, keyboardVisible]);

  const toggleInterruptPour = useCallback(() => {
    sendWsMessage({
      type: 'interrupt_pour',
      data: !interruptPour,
    });
  }, [interruptPour]);

  const toggleMockMindControl = useCallback(() => {
    setMockMindControl((m) => !m);
  }, [setMockMindControl]);

  return (
    <ConfigContainer>
      <div style={{ display: 'flex' }}>
        <ButtonContainer
          style={{ marginRight: '8px', paddingLeft: 0 }}
          onClick={back}
        >
          <BackIcon />
        </ButtonContainer>
        <Heading>Config</Heading>
      </div>

      <GroupHeading>Pour Controller</GroupHeading>
      <Item
        label="Pour Duration (s)"
        value={newParams.pourDurationSeconds}
        name="pourParamspourDurationSeconds"
        onFocus={() => {
          setKeyboardVisible(true);
          setCurrentFocusKey('pourDurationSeconds');
        }}
        onBlur={(e) => {
          console.log(e.target.selectionStart);
          setKeyboardVisible(false);
        }}
        onChange={(val) =>
          setNewParams((p) => ({ ...p, pourDurationSeconds: +val }))
        }
      />
      <Item
        label="Game practice time (s)"
        value={newParams.gamePracticeDuration}
        name="pourParamsgamePracticeDuration"
        onFocus={() => {
          setKeyboardVisible(true);
          setCurrentFocusKey('gamePracticeDuration');
        }}
        onBlur={() => setKeyboardVisible(false)}
        onChange={(val) =>
          setNewParams((p) => ({ ...p, gamePracticeDuration: +val }))
        }
      />
      <Item
        label="Optimal Glass Angle"
        value={newParams.glassBestAngle}
        name="pourParamsglassBestAngle"
        onFocus={() => {
          setKeyboardVisible(true);
          setCurrentFocusKey('glassBestAngle');
        }}
        onBlur={() => setKeyboardVisible(false)}
        onChange={(val) =>
          setNewParams((p) => ({ ...p, glassBestAngle: +val }))
        }
        onButtonPress={(v: number) => {
          pController.updateGlassAngle(v);
        }}
      />
      <Item
        label="Worst Glass Angle"
        value={newParams.glassWorstAngle}
        name="pourParamsglassWorstAngle"
        onFocus={() => {
          setKeyboardVisible(true);
          setCurrentFocusKey('glassWorstAngle');
        }}
        onBlur={() => setKeyboardVisible(false)}
        onChange={(val) =>
          setNewParams((p) => ({ ...p, glassWorstAngle: +val }))
        }
        onButtonPress={(v: number) => {
          pController.updateGlassAngle(v);
        }}
      />
      <Item
        label="Tap On Angle"
        value={newParams.tapOnAngle}
        name="pourParamstapOnAngle"
        onFocus={() => {
          setKeyboardVisible(true);
          setCurrentFocusKey('tapOnAngle');
        }}
        onBlur={() => setKeyboardVisible(false)}
        onChange={(val) => setNewParams((p) => ({ ...p, tapOnAngle: +val }))}
        onButtonPress={(v: number) => {
          pController.updateTapAngle(v);
        }}
      />
      <Item
        label="Tap Off Angle"
        value={newParams.tapOffAngle}
        name="pourParamstapOffAngle"
        onFocus={() => {
          setKeyboardVisible(true);
          setCurrentFocusKey('tapOffAngle');
        }}
        onBlur={() => setKeyboardVisible(false)}
        onChange={(val) => setNewParams((p) => ({ ...p, tapOffAngle: +val }))}
        onButtonPress={(v: number) => {
          pController.updateTapAngle(v);
        }}
      />
      <Item
        label="Min Focus"
        value={newParams.minFocus}
        name="pourParamsminFocus"
        onFocus={() => {
          setKeyboardVisible(true);
          setCurrentFocusKey('minFocus');
        }}
        onBlur={() => setKeyboardVisible(false)}
        onChange={(val) => setNewParams((p) => ({ ...p, minFocus: +val }))}
      />
      <Item
        label="Min Score Mapping"
        value={newParams.minScoreMapping}
        name="pourParamsminScoreMapping"
        onFocus={() => {
          setKeyboardVisible(true);
          setCurrentFocusKey('minScoreMapping');
        }}
        onBlur={() => setKeyboardVisible(false)}
        onChange={(val) =>
          setNewParams((p) => ({ ...p, minScoreMapping: +val }))
        }
      />
      <Item
        label="Min Attention"
        value={newParams.minAttention}
        name="pourParamsminAttention"
        onFocus={() => {
          setKeyboardVisible(true);
          setCurrentFocusKey('minAttention');
        }}
        onBlur={() => setKeyboardVisible(false)}
        onChange={(val) => setNewParams((p) => ({ ...p, minAttention: +val }))}
      />
      <Item
        label="Max Attention"
        value={newParams.maxAttention}
        name="pourParamsmaxAttention"
        onFocus={() => {
          setKeyboardVisible(true);
          setCurrentFocusKey('maxAttention');
        }}
        onBlur={() => setKeyboardVisible(false)}
        onChange={(val) => setNewParams((p) => ({ ...p, maxAttention: +val }))}
        buttonLabel="Reset"
        onButtonPress={() => {
          setNewParams((p) => ({
            ...p,
            maxAttention: pourParams.maxAttention,
            minAttention: pourParams.minAttention,
          }));
          resetAttentionThresholds();
        }}
      />

      <GroupHeading>Body</GroupHeading>
      <Item
        label="Min Droppers (per second)"
        value={newParams.minDroppers}
        name="pourParamsminDroppers"
        onFocus={() => {
          setKeyboardVisible(true);
          setCurrentFocusKey('minDroppers');
        }}
        onBlur={() => setKeyboardVisible(false)}
        onChange={(val) => setNewParams((p) => ({ ...p, minDroppers: +val }))}
      />
      <Item
        label="Max Droppers (per second)"
        value={newParams.maxDroppers}
        name="pourParamsmaxDroppers"
        onFocus={() => {
          setKeyboardVisible(true);
          setCurrentFocusKey('maxDroppers');
        }}
        onBlur={() => setKeyboardVisible(false)}
        onChange={(val) => setNewParams((p) => ({ ...p, maxDroppers: +val }))}
        buttonLabel="Reset"
        onButtonPress={() => {
          setNewParams((p) => ({
            ...p,
            maxDroppers: pourParams.maxDroppers,
            minDroppers: pourParams.minDroppers,
          }));
          resetDropperThresholds();
        }}
      />

      {useSoftwareKeyboard && keyboardVisible && (
        <KeyboardContainer onClick={(e) => e.preventDefault()}>
          <Keyboard
            keyboardRef={keyboard}
            inputName={`pourParams${currentFocusKey}`}
            layout={{
              default: ['1 2 3', '4 5 6', '7 8 9', '. 0 {bksp}'],
            }}
            display={{
              '{bksp}': '\u232B',
            }}
            onChange={(val: string) => {
              if (currentFocusKey) {
                setNewParams((p) => ({ ...p, [currentFocusKey]: +val }));
              }
            }}
          />
        </KeyboardContainer>
      )}

      {/* <GroupHeading>Load Sensor</GroupHeading>
      <Item label="Current Measured Mass" initial={32} />
      <Item label="Minimum Glass Threshold" initial={16} />
      <button style={{ width: '200px' }} onClick={() => console.log('tare')}>
        Tare
      </button> */}

      {/* <GroupHeading>Game</GroupHeading>
      <Item label="Easy Mode Offset" value={20} />
      <Item label="Medium Mode Offset" initial={40} />
      <Item label="Hard Mode Offset" initial={60} /> */}

      <GroupHeading>Headsets</GroupHeading>
      <button
        style={{
          background: 'black',
          marginBottom: '24px',
          width: 'fit-content',
          padding: '6px 18px',
        }}
        onClick={onConnectClick}
      >
        {localHeadsetConnected ? 'Disconnect' : 'Connect to headset'}
      </button>
      {localHeadsetConnected ? (
        <>
          <span>Connected to {headsetName}</span>
          <span>
            Battery:{' '}
            {headsetBattery == undefined ? 'Unknown' : `${headsetBattery}%`}
          </span>
          <span>Focus: {localHeadsetFocus}</span>
          <span>Acceleration: {acceleration}</span>
          <span>Headset: {headsetOn ? 'ON' : 'OFF'}</span>
          <span>Min attention: {pourParams.minAttention}</span>
          <span>Max attention: {pourParams.maxAttention}</span>
          <button
            style={{
              background: 'black',
              marginBottom: '24px',
              width: 'fit-content',
              padding: '6px 18px',
            }}
            disabled={calibrateMode}
            onClick={() => setCalibrateMode(true)}
          >
            {calibrateMode ? 'Calibrating...' : 'Calibrate'}
          </button>
        </>
      ) : null}
      {['None', ...headsetList].map((h, i) => (
        <button
          key={`headset-${h}`}
          style={{
            background: 'black',
            marginBottom: '24px',
            width: 'fit-content',
            padding: '6px 18px',
          }}
          onClick={() => {
            sendWsMessage({ type: 'change_headset', data: i });
          }}
        >
          {h}
        </button>
      ))}

      <GroupHeading>Data collection</GroupHeading>

      <ConfigItem>
        <Label htmlFor="preGamePersonalDetailEntry">Pre-game collection</Label>
        <div className="select">
          <select
            id="preGamePersonalDetailEntry"
            name="preGamePersonalDetailEntry"
            value={preGamePersonalDetailEntry}
            onChange={(e) =>
              setPreGamePersonalDetailEntry(
                +e.target.value as unknown as PersonalDetailEntry
              )
            }
          >
            <option value={PersonalDetailEntry.None}>None</option>
            <option value={PersonalDetailEntry.Form}>Form</option>
          </select>
        </div>
      </ConfigItem>

      {preGamePersonalDetailEntry == PersonalDetailEntry.Form && (
        <ConfigItem>
          <Label htmlFor="skipPreGamePersonalDetailEntry">
            Allow pre-game skip?
          </Label>
          <input
            type="checkbox"
            id="skipPreGamePersonalDetailEntry"
            name="skipPreGamePersonalDetailEntry"
            checked={skipPreGamePersonalDetailEntry}
            onChange={(e) =>
              setSkipPreGamePersonalDetailEntry(e.target.checked)
            }
          />
        </ConfigItem>
      )}

      <ConfigItem>
        <Label htmlFor="postGamePersonalDetailEntry">
          Post-game collection
        </Label>
        <div className="select">
          <select
            id="postGamePersonalDetailEntry"
            name="postGamePersonalDetailEntry"
            value={postGamePersonalDetailEntry}
            onChange={(e) =>
              setPostGamePersonalDetailEntry(
                +e.target.value as unknown as PersonalDetailEntry
              )
            }
          >
            <option value={PersonalDetailEntry.None}>None</option>
            <option value={PersonalDetailEntry.Form}>Form</option>
            <option value={PersonalDetailEntry.QrCode}>QR Code</option>
          </select>
        </div>
      </ConfigItem>

      <ConfigItem>
        <Label htmlFor="collectEmail">Collect email?</Label>
        <input
          type="checkbox"
          id="collectEmail"
          name="collectEmail"
          checked={collectEmail}
          onChange={(e) => setCollectEmail(e.target.checked)}
        />
      </ConfigItem>

      <GroupHeading>Misc Settings</GroupHeading>
      <ConfigItem>
        <Label htmlFor="useSoftwareKeyboard">Use software keyboard?</Label>
        <input
          type="checkbox"
          id="useSoftwareKeyboard"
          name="useSoftwareKeyboard"
          checked={useSoftwareKeyboard}
          onChange={(e) => setUseSoftwareKeyboard(e.target.checked)}
        />
      </ConfigItem>

      <button
        style={{
          background: 'black',
          marginBottom: '24px',
          width: 'fit-content',
          padding: '18px',
        }}
        onClick={toggleCursorVisibility}
      >
        Toggle cursor
      </button>

      <button
        style={{
          background: 'black',
          marginBottom: '24px',
          width: 'fit-content',
          padding: '18px',
        }}
        onClick={() => {
          setCurrentEvent(null);
          setCurrentMode(AppState.Home);
        }}
      >
        Change event
      </button>

      <button
        style={{
          background: 'black',
          marginBottom: '24px',
          width: 'fit-content',
          padding: '18px',
        }}
        onClick={toggleInterruptPour}
      >
        {interruptPour ? 'Allow pour' : 'Interrupt pour'}
      </button>

      <button
        style={{
          background: mockMindControl ? 'black' : 'red',
          marginBottom: '24px',
          width: 'fit-content',
          padding: '18px',
        }}
        onClick={toggleMockMindControl}
      >
        {mockMindControl ? 'Real mind control' : 'Mock mind control'}
      </button>

      <Button
        style={{
          paddingTop: '4px',
          paddingBottom: '4px',
          // marginTop: 'auto',
          width: '50vw',
          position: 'fixed',
          bottom: '15px',
          left: '25vw',
          right: '25vw',
        }}
        onClick={() => {
          setPourParams(newParams);
          toast.success('Updated Pour Parameters');
        }}
      >
        Save
      </Button>
    </ConfigContainer>
  );
};

export default Config;

const KeyboardContainer = styled.div`
  position: fixed;
  bottom: 80px;
  height: 200px;
  display: flex;
  align-items: flex-end;
  left: 20vw;
  right: 20vw;
  z-index: 10000;
  .hg-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: stretch;
    align-items: stretch;
    gap: 5px;

    .hg-standardBtn,
    .hgfunctionBtn {
      width: auto;
      margin-right: 0 !important;
    }
  }
  span {
    color: #000;
  }
`;

const ConfigContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  /* gap: 32px; */
  padding: 64px;
  overflow: auto;
`;

const ConfigItem = styled.li`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  font-size: 32px;
  font-family: 'Bevellier', 'Inter', sans-serif;

  --form-control-focus: rgba(255, 255, 255, 0.2);
  --form-control-color: ${(p) => p.theme.colors.primary};
  --form-control-disabled: #959495;
  --select-border: ${(p) => p.theme.colors.primary};
  --select-focus: blue;
  --select-arrow: var(--select-border);

  input[type='checkbox'] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: transparent;
    /* Not removed via appearance */
    margin: 0 0 12px 0;

    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid currentColor;
    border-radius: 0.15em;
    transform: translateY(-0.075em);

    display: grid;
    place-content: center;
  }

  input[type='checkbox']::before {
    content: '';
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    /* Windows High Contrast Mode */
    background-color: CanvasText;
  }

  input[type='checkbox']:checked::before {
    transform: scale(1);
  }

  input[type='checkbox']:focus {
    outline: max(2px, 0.15em) solid var(--form-control-focus);
    outline-offset: max(2px, 0.15em);
  }

  input[type='checkbox']:disabled {
    --form-control-color: var(--form-control-disabled);

    color: var(--form-control-disabled);
    cursor: not-allowed;
  }

  .select {
    width: 100%;
    min-width: 15ch;
    max-width: 30ch;
    border: 2px solid var(--select-border);
    padding: 0.25em 0.5em;
    font-size: inherit;
    cursor: pointer;
    line-height: 1.1;
    background-color: transparent;
    display: grid;
    grid-template-areas: 'select';
    align-items: center;
  }

  .select::after {
    content: '';
    width: 0.8em;
    height: 0.5em;
    background-color: var(--select-arrow);
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    grid-area: select;
    justify-self: end;
  }

  select {
    // A reset of styles, including removing the default dropdown arrow
    appearance: none;
    // Additional resets for further consistency
    background-color: transparent;
    color: currentColor;
    border: none;
    padding: 0 1em 0 0;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    // width: 30vw;
    outline: none;
    grid-area: select;
  }
`;

interface ItemProps {
  label: string;
  value: number;
  name?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onChange: (v: number) => void;
  onButtonPress?: (v: number) => void;
  buttonLabel?: string;
}

const Item = ({
  label,
  value,
  name,
  onBlur,
  onChange,
  onFocus,
  onButtonPress,
  buttonLabel = 'Send',
}: ItemProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.currentTarget.valueAsNumber);
  };
  return (
    <ConfigItem>
      <Label>{label}</Label>
      <Input
        type="number"
        id={name}
        name={name}
        value={value}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={handleChange}
      />
      {onButtonPress !== undefined ? (
        <Button
          style={{ width: 'fit-content', marginLeft: '10px' }}
          onClick={() => {
            onButtonPress(value);
          }}
        >
          {buttonLabel}
        </Button>
      ) : null}
    </ConfigItem>
  );
};

const Label = styled.label`
  font-family: 'Bevellier', 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 25px;
  min-width: 256px;
  padding-top: 2px;
  padding-bottom: 2px;
`;

const GroupHeading = styled.h2`
  font-family: 'Bevellier', 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 25px;
  letter-spacing: 0.065em;
  text-transform: uppercase;
  margin-top: 48px;
  margin-bottom: 16px;
`;

const Heading = styled.h1`
  font-family: 'Bevellier', 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 3rem;
  line-height: 55px;
`;

const Input = styled.input`
  border: none;
  outline: none;
  background-color: ${(p) => p.theme.colors.bg};
  color: ${(p) => p.theme.colors.primary};
  border: 2px solid ${(p) => p.theme.colors.primary};
  width: 30vw;
  padding: 2px;
  padding-left: 8px;
`;
