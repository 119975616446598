import styled from 'styled-components';

const BackIcon = (): JSX.Element => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6"
    fill="none"
    viewBox="0 0 24 24"
    width="32px"
    height="32px"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M7 16l-4-4m0 0l4-4m-4 4h18"
    />
  </SVG>
);

export default BackIcon;

const SVG = styled.svg`
  color: ${(p) => p.theme.colors.primary};
`;
