import { useCallback, useContext } from 'react';

import { AppState, useGlobalState } from './globalStateProvider';
import { WebSocketContext } from './sockets/ws-provider';
import { usePersonalDetails } from './usePersonalDetails';

const useModeChanger = () => {
  const { setCurrentMode, robotDemoMode, setSkin } = useGlobalState();
  const { reset } = usePersonalDetails();
  const { sendWsMessage } = useContext(WebSocketContext);

  const changeMode = useCallback(
    (mode: AppState, skin?: string) => {
      reset();
      if (skin) {
        setSkin(skin);
      }
      if (robotDemoMode) {
        setCurrentMode(mode);
      } else {
        sendWsMessage({ type: 'change_mode', data: mode });
      }
    },
    [robotDemoMode]
  );

  return { changeMode };
};

export default useModeChanger;
