import styled from 'styled-components';

const Input = styled.input`
  position: relative;
  border: none;
  background: none;
  outline: none;

  padding: 14px;
  border: double 3px transparent;
  border-radius: 14px;
  background-image: linear-gradient(#2e2b3b, #2e2b3b),
    linear-gradient(
      93.41deg,
      ${(p) =>
        p.theme.colors.inputGradient
          .map((g) => `${g.color} ${g.offset}`)
          .join(', ')}
    );
  background-origin: border-box;
  background-clip: padding-box, border-box;
  color: #f2f0ff;
`;

export default Input;
