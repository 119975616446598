import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';

const container = document.getElementById('root');
if (!container) throw new Error('Could not bind to app root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// TODO: add service worker to make installable as PWA
// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.register('./service-worker.js');
// }
