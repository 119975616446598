import { createContext, useState, useEffect, useContext } from 'react';
import { ConnectionStatusData } from './sockets/ws-provider';
import { Account, Event } from './types/db-models';
import useLocalStorage from './useLocalStorage';

export enum AppState {
  Mind,
  Body,
  Button,
  Home,
  Config,
  Race,
}

export enum PersonalDetailEntry {
  None,
  Form,
  QrCode,
}

export type SelectableState =
  | AppState.Body
  | AppState.Mind
  | AppState.Race
  | AppState.Button;

export const DEFAULT_MIN_ATTENTION = 0;
export const ATTENTION_EXPONENT = 3;
export const DEFAULT_MAX_ATTENTION = Math.pow(0.25, ATTENTION_EXPONENT);
export const MAX_ATTENTION_MULTIPLIER = 1.5;
export const DEFAULT_MIN_DROPPERS = 1;
export const DEFAULT_MAX_DROPPERS = 7;

export interface PourParams {
  pourDurationSeconds: number;
  gamePracticeDuration: number;
  glassBestAngle: number;
  glassWorstAngle: number;
  tapOffAngle: number;
  tapOnAngle: number;
  minFocus: number;
  minScoreMapping: number;
  minAttention: number;
  maxAttention: number;
  minDroppers: number;
  maxDroppers: number;
}

const initialParams: PourParams = {
  pourDurationSeconds: 16,
  gamePracticeDuration: 15,
  glassBestAngle: 2100,
  glassWorstAngle: 1500,
  tapOffAngle: 1500,
  tapOnAngle: 2100,
  minFocus: 10,
  minScoreMapping: 10,
  minAttention: DEFAULT_MIN_ATTENTION,
  maxAttention: DEFAULT_MAX_ATTENTION,
  minDroppers: DEFAULT_MIN_DROPPERS,
  maxDroppers: DEFAULT_MAX_DROPPERS,
};

interface GlobalState {
  account: Account | null;
  setAccount: (a: Account) => void;
  currentEvent: Event | null;
  setCurrentEvent: (e: Event | null) => void;
  currentMode: AppState;
  skin: string;
  setSkin: (s: string) => void;
  defaultMode: AppState;
  setDefaultMode: (newMode: AppState) => void;
  defaultSkin: string;
  setDefaultSkin: (newSkin: string) => void;
  hasRobotConnection: boolean;
  setCurrentMode: (newMode: AppState) => void;
  setConnectionStatus: (newStatus: boolean) => void;
  connectionStatus: boolean;
  setRobotDemoMode: (newRobotDemoMode: boolean) => void;
  robotDemoMode: boolean;
  setSkipPreGamePersonalDetailEntry: (newSkip: boolean) => void;
  skipPreGamePersonalDetailEntry: boolean;
  setCollectEmail: (newCollectEmail: boolean) => void;
  collectEmail: boolean;
  setUseSoftwareKeyboard: (newUse: boolean) => void;
  useSoftwareKeyboard: boolean;
  setPreGamePersonalDetailEntry: (
    newPersonalDetailEntry: PersonalDetailEntry
  ) => void;
  preGamePersonalDetailEntry: PersonalDetailEntry;
  setPostGamePersonalDetailEntry: (
    newPersonalDetailEntry: PersonalDetailEntry
  ) => void;
  postGamePersonalDetailEntry: PersonalDetailEntry;
  interruptPour: boolean;
  setInterruptPour: (b: boolean) => void;
  cupDetected: boolean;
  setCupDetected: (b: boolean) => void;
  hasConnectionError: boolean;
  setHasConnectionError: (b: boolean) => void;
  pourParams: PourParams;
  setPourParams: (p: PourParams | ((p: PourParams) => PourParams)) => void;
  connectionStatusData: ConnectionStatusData;
  setConnectionStatusData: (c: ConnectionStatusData) => void;
  toggleCursorVisibility: () => void;
  setMockMindControl: (b: boolean | ((b: boolean) => boolean)) => void;
  mockMindControl: boolean;
  headsetList: string[];
  setHeadsetList: (s: string[]) => void;
  localHeadsetConnected: boolean;
  setLocalHeadsetConnected: (b: boolean) => void;
  localHeadsetFocus: number;
  setLocalHeadsetFocus: (f: number | ((f: number) => number)) => void;
  resetAttentionThresholds: () => void;
  resetDropperThresholds: () => void;
}

export const GlobalStateContext = createContext({
  hasRobotConnection: false,
} as GlobalState);

export const useGlobalState = (): GlobalState => useContext(GlobalStateContext);

export const GlobalStateProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const [account, setAccount] = useState<Account | null>(null);
  const [defaultMode, setDefaultMode] = useLocalStorage(
    'as-default-mode',
    AppState.Mind
  );
  const [defaultSkin, setDefaultSkin] = useLocalStorage('as-default-skin', '');
  const [currentMode, setCurrentMode] = useState<AppState>(AppState.Home);
  const [skin, setSkin] = useState<string>('');
  const [robotDemoMode, setRobotDemoMode] = useState(false);
  const [hasConnection, setHasConnection] = useState(false);
  const [interruptPour, setInterruptPour] = useState(false);
  const [mockMindControl, setMockMindControl] = useLocalStorage(
    'as-mock-mind-control',
    false
  );
  const [skipPreGamePersonalDetailEntry, setSkipPreGamePersonalDetailEntry] =
    useLocalStorage('as-skip-pre-game-personal-detail-entry', false);
  const [collectEmail, setCollectEmail] = useLocalStorage(
    'as-collect-email',
    true
  );
  const [useSoftwareKeyboard, setUseSoftwareKeyboard] = useLocalStorage(
    'as-software-keyboard',
    true
  );
  const [preGamePersonalDetailEntry, setPreGamePersonalDetailEntry] =
    useLocalStorage(
      'as-pre-game-personal-detail-entry',
      PersonalDetailEntry.None
    );
  const [postGamePersonalDetailEntry, setPostGamePersonalDetailEntry] =
    useLocalStorage(
      'as-post-game-personal-detail-entry',
      PersonalDetailEntry.QrCode
    );
  const [headsetList, setHeadsetList] = useState<string[]>([]);
  const [localHeadsetConnected, setLocalHeadsetConnected] =
    useState<boolean>(false);
  const [localHeadsetFocus, setLocalHeadsetFocus] = useState<number>(0);
  const [cupDetected, setCupDetected] = useState(true); //TODO: change?
  const [hasConnectionError, setHasConnectionError] = useState(false);
  const [pourParams, setPourParams] = useLocalStorage(
    'as-pour-params',
    initialParams
  );
  const [connectionStatusData, setConnectionStatusData] =
    useState<ConnectionStatusData>({
      headset_connection: 200,
      robot_connected: false,
    });
  const [cursorHidden, setCursorHidden] = useLocalStorage(
    'as-cursor-hidden',
    false
  );
  const [currentEvent, setCurrentEvent] = useLocalStorage<Event | null>(
    'as-current-event',
    null
  );

  const toggleCursorVisibility = () => {
    const targetHiddenState = !cursorHidden;
    if (targetHiddenState == true) {
      document.documentElement.style.cursor = 'none';
      document.body.classList.add('hide-cursor');
    } else {
      document.documentElement.style.cursor = 'auto';
      document.body.classList.remove('hide-cursor');
    }
    setCursorHidden(targetHiddenState);
  };

  useEffect(() => {
    if (cursorHidden == true) {
      document.documentElement.style.cursor = 'none';
      document.body.classList.add('hide-cursor');
    } else {
      document.documentElement.style.cursor = 'auto';
      document.body.classList.remove('hide-cursor');
    }
  }, []);

  useEffect(() => {
    console.log({ hasConnection, hasConnectionError });
  }, [hasConnection, hasConnectionError]);

  useEffect(() => {
    // TODO: read connection ID
    // make connection request - here or inside ws provider?

    if (!hasConnection) {
    }
  }, []);

  useEffect(() => {
    console.log({ cupDetected });
  }, [cupDetected]);

  useEffect(() => {
    if (pourParams.minAttention === undefined) {
      setPourParams((p) => ({ ...p, minAttention: DEFAULT_MIN_ATTENTION }));
    }
  }, [pourParams.minAttention]);

  useEffect(() => {
    if (pourParams.maxAttention === undefined) {
      setPourParams((p) => ({ ...p, maxAttention: DEFAULT_MAX_ATTENTION }));
    }
  }, [pourParams.maxAttention]);

  const resetAttentionThresholds = () => {
    setPourParams((p) => ({
      ...p,
      minAttention: DEFAULT_MIN_ATTENTION,
      maxAttention: DEFAULT_MAX_ATTENTION,
    }));
  };

  const resetDropperThresholds = () => {
    setPourParams((p) => ({
      ...p,
      minDroppers: DEFAULT_MIN_DROPPERS,
      maxDroppers: DEFAULT_MAX_DROPPERS,
    }));
  };

  return (
    <GlobalStateContext.Provider
      value={{
        account,
        setAccount,
        currentEvent,
        setCurrentEvent,
        headsetList,
        setHeadsetList,
        currentMode,
        skin,
        setSkin,
        defaultMode,
        setDefaultMode,
        defaultSkin,
        setDefaultSkin,
        hasRobotConnection: hasConnection,
        setCurrentMode,
        setConnectionStatus: setHasConnection,
        connectionStatus: hasConnection,
        setRobotDemoMode,
        robotDemoMode,
        setSkipPreGamePersonalDetailEntry,
        skipPreGamePersonalDetailEntry,
        collectEmail,
        setCollectEmail,
        useSoftwareKeyboard,
        setUseSoftwareKeyboard,
        setPreGamePersonalDetailEntry,
        preGamePersonalDetailEntry,
        setPostGamePersonalDetailEntry,
        postGamePersonalDetailEntry,
        cupDetected,
        setCupDetected,
        hasConnectionError,
        setHasConnectionError,
        pourParams,
        setPourParams,
        connectionStatusData,
        setConnectionStatusData,
        toggleCursorVisibility,
        localHeadsetConnected,
        setLocalHeadsetConnected,
        localHeadsetFocus,
        setLocalHeadsetFocus,
        resetAttentionThresholds,
        resetDropperThresholds,
        interruptPour,
        setInterruptPour,
        mockMindControl,
        setMockMindControl,
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};
