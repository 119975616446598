import { useEffect, useState } from 'react';
import styled from 'styled-components';
import ReactConfetti from 'react-confetti';

import useSubmitScore from '../lib/db/useSubmitScore';
import { GameMode } from '../lib/types/game-types';

import Button from './Button';
import { FullPageContainer, Dialog } from './Layout';
import Loader from './Loader';
import ScoreQrDialog from './scores/ScoreQrDialog';
import { usePersonalDetails } from '../lib/usePersonalDetails';
import PersonalDetails from './PersonalDetails';
import {
  PersonalDetailEntry,
  useGlobalState,
} from '../lib/globalStateProvider';
import useSubmitEmail from '../lib/db/useSubmitEmail';

interface Dialog {
  rank: string;
  minScore: number;
  maxScore: number;
  text: string;
}

const dialogs: Dialog[] = [
  {
    rank: 'BEGINNER',
    text: 'Wow. That was bad...Try again?',
    minScore: 0,
    maxScore: 400,
  },
  {
    rank: 'INTERMEDIATE',
    text: 'Hmm, not good, not bad - just OK',
    minScore: 401,
    maxScore: 750,
  },
  {
    rank: 'PRO',
    text: 'Wow. Pretty great!',
    minScore: 751,
    maxScore: 900,
  },
  {
    rank: 'JEDI',
    text: 'The force is strong with you!',
    minScore: 901,
    maxScore: 10000,
  },
];

const getDialog = (score: number): Dialog => {
  const res = dialogs.find((d) => score >= d.minScore && score <= d.maxScore);
  if (!res)
    return {
      rank: 'Congratulations!',
      text: '',
      minScore: 0,
      maxScore: 0,
    };
  return res;
};

interface PostGameDialogProps {
  callback: () => void;
  score: number;
  focusScore?: number | null;
  mode: GameMode;
  pourId: number;
  dialogStartText?: string;
  scoreUnits?: string;
}

const PostGameDialog = ({
  callback,
  score,
  focusScore = null,
  dialogStartText = 'You scored',
  scoreUnits = ' points',
  pourId,
}: PostGameDialogProps): JSX.Element => {
  const { postGamePersonalDetailEntry } = useGlobalState();
  const { confirmed, name, email } = usePersonalDetails();
  const pos = 0;
  const submit = useSubmitScore(pourId, score, confirmed ? name : undefined);
  const submitEmail = useSubmitEmail(pourId, confirmed ? email : undefined);
  const dialog = getDialog(focusScore ?? score);
  // We use this so that when the state is reset we don't unset the name
  const [localName, setLocalName] = useState<string>();

  useEffect(() => {
    if (confirmed) {
      setLocalName(name);
    }
  }, [confirmed, name]);

  useEffect(() => {
    submit.mutate({
      id: pourId,
      score,
      name: localName,
    });
  }, [localName, score]);

  useEffect(() => {
    if (!confirmed || !email) {
      return;
    }
    submitEmail.mutate({
      id: pourId,
      email: confirmed ? email : undefined,
    });
  }, [confirmed, email]);

  return (
    <FullPageContainer>
      <Dialog>
        {dialog.rank.length < 9 ? (
          <RankText>{dialog.rank}</RankText>
        ) : (
          <SmallerRankText>{dialog.rank}</SmallerRankText>
        )}
        <ReactConfetti
          recycle={false}
          colors={['#6FFFF6', '#EF75E0', '#434053', '#fff']}
          numberOfPieces={402}
        />
        <h1>{dialog.text}</h1>
        <p>
          {`${dialogStartText} `}
          <span style={{ fontSize: '32px' }}>{score.toFixed(2)}</span>
          {scoreUnits}.
        </p>
        {postGamePersonalDetailEntry === PersonalDetailEntry.Form &&
          !confirmed && <PersonalDetails />}
        {postGamePersonalDetailEntry === PersonalDetailEntry.QrCode &&
          !confirmed && (
            <>
              <p>Scan the QR code to add your name to the scoreboard</p>
              {pourId && submit.isSuccess ? (
                <ScoreQrDialog score={score} pos={pos} scoreId={pourId} />
              ) : (
                <div style={{ width: '196px', height: '196px' }}>
                  <Loader />
                </div>
              )}
            </>
          )}

        <Button onClick={callback}>HOME</Button>
      </Dialog>
    </FullPageContainer>
  );
};

export default PostGameDialog;

const RankText = styled.h1`
  font-size: 120px;
`;
const SmallerRankText = styled.h1`
  font-size: 80px;
`;
