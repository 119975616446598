import { useMutation, UseMutationResult } from 'react-query';

import { supabase } from './client';
import { Table } from '../types/db-models';

const submitEmail = async (id: number, email?: string): Promise<boolean> => {
  if (!email) {
    return true;
  }
  const { error } = await supabase
    .from(Table.EMAIL)
    .insert({ email, pour_id: id }, { returning: 'minimal' });
  if (error) throw new Error(error.message);
  return true;
};

interface SubmitEmailParams {
  id: number;
  email?: string;
}

const useSubmitEmail = (
  id: number,
  email?: string
): UseMutationResult<boolean, Error, SubmitEmailParams> => {
  return useMutation('submit-email', () => submitEmail(id, email), {
    onSuccess: () => console.log('successfully wrote email'),
  });
};

export default useSubmitEmail;
