import { useEffect, useRef, useState } from 'react';
import { Pose, Results, POSE_LANDMARKS } from '@mediapipe/pose';
import { HandPos, initialHandPos } from '../components/games/dropper/main';

interface UseHandsEstimation {
  init: () => void;
  load: () => void;
  update: () => void;
  handPos: HandPos;
  initialised: boolean;
}

interface UseHandsEstimationArgs {
  webcamRef: React.MutableRefObject<HTMLVideoElement | null>;
}

const MIN_VISIBILITY = 0.3;

const useHandsEstimation = ({
  webcamRef,
}: UseHandsEstimationArgs): UseHandsEstimation => {
  const poseRef = useRef<Pose | null>(null);
  const streamRef = useRef<MediaStream | null>(null);
  const [initialised, setInitialised] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [handPos, setHandPos] = useState<HandPos>({
    left: { ...initialHandPos.left },
    right: { ...initialHandPos.right },
  });

  const closeStream = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => {
        console.log('closing track', track);
        track.stop();
      });
    }
  };

  useEffect(() => {
    return () => closeStream();
  }, []);

  const init = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({
      video: true,
    });

    streamRef.current = stream;

    console.log(stream);

    if (webcamRef.current !== null) {
      webcamRef.current.srcObject = stream;
      webcamRef.current.play();
      loadModel();
    }
  };

  // Better performance by caching this?
  const loadModel = async () => {
    console.log('loading CV model');
    poseRef.current = new Pose({
      locateFile: (file) => {
        return `./mediapipe/pose/${file}`; // TODO: use webpack to copy this folder from node_modules
      },
    });
    poseRef.current.setOptions({ modelComplexity: 0, smoothLandmarks: true });
    poseRef.current.onResults(onResults);

    try {
      await poseRef.current.initialize();
      setInitialised(true);
    } catch (error) {
      console.error(error);
    }
  };

  const update = async () => {
    if (webcamRef.current !== null) {
      await poseRef.current?.send({
        image: webcamRef.current,
      });
    } else {
      console.log('could not update as no webcam ref');
    }
  };

  const onResults = (results: Results) => {
    if (!results.poseLandmarks || results.poseLandmarks.length === 0) return;

    // TODO: calculate midpoint between wrist and fingers
    const left = results.poseLandmarks.find(
      (v, i) => i === POSE_LANDMARKS.LEFT_WRIST
    );
    const right = results.poseLandmarks.find(
      (v, i) => i === POSE_LANDMARKS.RIGHT_WRIST
    );

    const oldLeft = handPos.left;
    const oldRight = handPos.right;
    const newHandPos = {
      left: { ...initialHandPos.left },
      right: { ...initialHandPos.right },
    };

    if (left && left.visibility && left.visibility >= MIN_VISIBILITY) {
      newHandPos.left = {
        x: (1 - left.x) * window.innerWidth,
        y: left.y * window.innerHeight,
      };
    } else {
      newHandPos.left = oldLeft;
    }

    if (right && right.visibility && right.visibility >= MIN_VISIBILITY) {
      newHandPos.right = {
        x: (1 - right.x) * window.innerWidth,
        y: right.y * window.innerHeight,
      };
    } else {
      newHandPos.right = oldRight;
    }

    setHandPos(newHandPos);
  };

  return {
    init,
    load: loadModel,
    update,
    handPos,
    initialised,
  };
};

export default useHandsEstimation;
