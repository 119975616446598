import { P5CanvasInstance, Sketch, SketchProps } from '@p5-wrapper/react';

import TopDisplay from '../shared/topDisplay';
import { Font, Image } from 'p5';

export enum SoundEffectType {
  Idle,
  Rev,
  Accelerate,
  End,
}

export type RaceModeSkin = {
  colors: Colors;
  scaleMultiplier: number;
  playerStaticImageUrls?: string[];
  playerStraightImageUrls: string[];
  skyImageUrl: string;
  playerBounce: boolean;
  fitSkyToHeight?: boolean;
  glowLines?: boolean;
  drawRoadFirst?: boolean;
  billboardSpacingMin?: number;
  billboardSpacingMax?: number;
  billboardStartOffset?: number;
  billboardRandomise?: boolean;
  billboardImages: {
    url: string;
    scale?: number;
    brandScale?: number;
    graphicPositions?: [number, number][];
  }[];
  parallaxImageUrls: string[];
  sceneryImages: {
    url: string;
    offset: number;
    minMultiplier: number;
    maxMultiplier: number;
    scale?: number;
    verticalOffsetMin?: number;
    verticalOffsetMax?: number;
  }[];
};

interface RaceGameProps extends SketchProps {
  focusLevel: number;
  topColor?: string;
  _pourTime: number;
  _practiceTime: number;
  skin: RaceModeSkin;
  onComplete: (distance: number) => void;
  playSoundEffect: (type: SoundEffectType) => void;
  stopSoundEffect: (type: SoundEffectType) => void;
}

interface ColorStruct {
  road: string;
  grass: string;
  rumble: string;
  lane?: string;
}

export interface Colors {
  FOG?: string;
  LIGHT: ColorStruct;
  DARK: ColorStruct;
  START: ColorStruct;
  FINISH: ColorStruct;
}

interface ImageWithGif extends Image {
  gifProperties: null | {
    displayIndex: number;
    numFrames: number;
  };
}

interface Vector3 {
  x: number;
  y: number;
  z: number;
}

interface RoadPosition {
  world: {
    x?: number;
    y?: number;
    z: number;
  };
}

interface ProjectedRoadPosition extends RoadPosition {
  camera: Vector3;
  screen: {
    x: number;
    y: number;
    w: number;
    scale: number;
  };
}

interface SpriteDescription {
  source: Image;
  offset: number;
  scale: number;
  verticalOffset?: number;
}

interface RoadSegmentDescription {
  index: number;
  p1: RoadPosition | ProjectedRoadPosition;
  p2: RoadPosition | ProjectedRoadPosition;
  color: ColorStruct;
  curve: number;
  clip: number;
  sprites: SpriteDescription[];
  fog?: number;
  looped?: boolean;
}

const ROAD = {
  LENGTH: { NONE: 0, SHORT: 25, MEDIUM: 50, LONG: 100 },
  CURVE: { NONE: 0, EASY: 2, MEDIUM: 4, HARD: 6 },
};

export const TOP_H = 120;

export const raceSketch: Sketch<RaceGameProps> = (p5) => {
  let TYPEFACE: Font;
  let pourTime = 0;
  let practiceTime = 0;
  let timeRemaining = practiceTime + pourTime;

  let focusLevel = 0;
  let beerIconUrl = 'assets/beer-icon.png';
  let beerIconSize = 120;

  let onCompleteCallback: (distance: number) => void;
  let playSoundEffectCallback: (type: SoundEffectType) => void;
  let stopSoundEffectCallback: (type: SoundEffectType) => void;

  const segmentLength = 200;
  const drawDistance = 300;
  const fieldOfView = 100;
  const step = 1 / 60;
  let speed = 0;
  const maxSpeed = segmentLength / step;
  const accel = maxSpeed / 3;
  const decel = -accel;
  let topColor: string;

  let billboardSpacingMin = 50;
  let billboardSpacingMax = 50;
  let billboardStart = 25;
  let billboardRandomise = false;

  let spritesBuilt = false;
  let billboardsBuilt = false;

  let drawRoadFirst = true;

  let isAccelerating: boolean;
  let isDecelerating: boolean;

  let position = 0;

  let skin: RaceModeSkin;
  let road: Road;
  let topDisplay: TopDisplay<RaceGameProps>;
  let playerStaticImages: Image[] = [];
  const playerStraightImages: Image[] = [];
  let playerIndex = 0;
  let lightsOut: LightsOut;

  let beerIcon: Image;
  let skyImage: Image;
  const parallaxImages: Image[] = [];
  const sceneryImages: Image[] = [];
  const billboardImages: Image[] = [];

  p5.updateWithProps = (props) => {
    if (typeof props.focusLevel == 'number') {
      focusLevel = props.focusLevel;
    }

    if (typeof props._pourTime == 'number') {
      pourTime = props._pourTime;
    }

    if (typeof props._practiceTime == 'number') {
      practiceTime = props._practiceTime;
    }

    if (typeof props.beerIconUrl == 'string' && props.beerIconUrl != '') {
      if (beerIconUrl != props.beerIconUrl) {
        beerIconUrl = props.beerIconUrl;
        p5.loadImage(beerIconUrl, (image) => {
          beerIcon = image;
        });
      }
    }

    if (
      typeof props.skin === 'object' &&
      props.skin !== null &&
      skin !== props.skin
    ) {
      skin = props.skin;
      const playerImagesHash: Record<string, Image> = {};
      for (const url of skin.playerStraightImageUrls) {
        p5.loadImage(url, (image) => {
          playerImagesHash[skin.playerStraightImageUrls.indexOf(url)] = image;
          const keys = Object.keys(playerImagesHash);
          if (keys.length === skin.playerStraightImageUrls.length) {
            keys.sort().forEach((k) => {
              playerStraightImages.push(playerImagesHash[k]);
            });
            if (skin.playerStaticImageUrls === undefined) {
              playerStaticImages = [...playerStraightImages];
            }
          }
        });
      }
      const playerStaticImagesHash: Record<string, Image> = {};
      const playerStaticImageUrls = skin.playerStaticImageUrls ?? [];
      for (const url of playerStaticImageUrls) {
        p5.loadImage(url, (image) => {
          playerStaticImagesHash[playerStaticImageUrls.indexOf(url)] = image;
          const keys = Object.keys(playerStaticImagesHash);
          if (keys.length === playerStaticImageUrls.length) {
            keys.sort().forEach((k) => {
              playerStaticImages.push(playerStaticImagesHash[k]);
            });
          }
        });
      }
      p5.loadImage(skin.skyImageUrl, (image) => {
        skyImage = image;
      });
      const parallaxImagesHash: Record<string, Image> = {};
      for (const url of skin.parallaxImageUrls) {
        p5.loadImage(url, (image) => {
          parallaxImagesHash[skin.parallaxImageUrls.indexOf(url)] = image;
          const keys = Object.keys(parallaxImagesHash);
          if (keys.length === skin.parallaxImageUrls.length) {
            keys.sort().forEach((k) => {
              parallaxImages.push(parallaxImagesHash[k]);
            });
          }
        });
      }
      const sceneryImagesHash: Record<string, Image> = {};
      let index = 0;
      for (const scenery of skin.sceneryImages) {
        const currentKey = index;
        p5.loadImage(
          scenery.url,
          (image) => {
            sceneryImagesHash[currentKey] = image;
            const keys = Object.keys(sceneryImagesHash);
            if (keys.length === skin.sceneryImages.length) {
              keys.sort().forEach((k) => {
                sceneryImages.push(sceneryImagesHash[k]);
              });
            }
          },
          () => {
            console.error(`Failed to load ${scenery.url}`);
          }
        );
        index += 1;
      }
      const billboardImagesHash: Record<string, Image> = {};
      index = 0;
      for (const billboard of skin.billboardImages) {
        const currentKey = index;
        p5.loadImage(
          billboard.url,
          (image) => {
            billboardImagesHash[currentKey] = image;
            const keys = Object.keys(billboardImagesHash);
            if (keys.length === skin.billboardImages.length) {
              keys.sort().forEach((k) => {
                billboardImages.push(billboardImagesHash[k]);
              });
            }
          },
          () => {
            console.error(`Failed to load ${billboard.url}`);
          }
        );
        index += 1;
      }

      if (typeof skin.billboardStartOffset === 'number') {
        billboardStart = skin.billboardStartOffset;
      }
      if (typeof skin.billboardSpacingMin === 'number') {
        billboardSpacingMin = skin.billboardSpacingMin;
      }
      if (typeof skin.billboardSpacingMax === 'number') {
        billboardSpacingMax = skin.billboardSpacingMax;
      }
      if (typeof skin.billboardRandomise === 'boolean') {
        billboardRandomise = skin.billboardRandomise;
      }
    }

    if (typeof props.beerIconSize == 'number' && props.beerIconSize > 0) {
      beerIconSize = props.beerIconSize;
    }

    if (typeof props.topColor === 'string') {
      topColor = props.topColor;
    }

    if (typeof props.drawRoadFirst === 'boolean') {
      drawRoadFirst = props.drawRoadFirst;
    }

    if (typeof props.onComplete == 'function') {
      onCompleteCallback = props.onComplete;
    }
    if (typeof props.playSoundEffect == 'function') {
      playSoundEffectCallback = props.playSoundEffect;
    }
    if (typeof props.stopSoundEffect == 'function') {
      stopSoundEffectCallback = props.stopSoundEffect;
    }
  };

  p5.preload = () => {
    TYPEFACE = p5.loadFont('assets/ShareTechMono-Regular.ttf');
    beerIcon = p5.loadImage(beerIconUrl);
  };

  p5.setup = () => {
    p5.pixelDensity(1);
    p5.createCanvas(window.innerWidth, window.innerHeight);
    p5.textFont(TYPEFACE);

    road = new Road(p5, segmentLength, fieldOfView, skin);
    lightsOut = new LightsOut(p5);

    buildBillboards();
    buildSprites();

    topDisplay = new TopDisplay<RaceGameProps>(p5, topColor);

    timeRemaining = pourTime + practiceTime;
    console.log({ timeRemaining, pourTime, practiceTime });
  };

  const randomInt = (a: number, b: number) => {
    const min = Math.min(a, b);
    const max = Math.max(a, b) + 1;
    return min + Math.floor(Math.random() * (max - min));
  };

  const buildBillboards = () => {
    if (billboardsBuilt) {
      return;
    }
    if (skin.billboardImages.length !== billboardImages.length) {
      return;
    }
    const beerIconHeight = beerIconSize;
    const beerIconWidth = beerIcon.width * (beerIconHeight / beerIcon.height);

    let index = 0;
    for (const billboardImage of billboardImages) {
      const config = skin.billboardImages[index];
      const positions = config.graphicPositions ?? [[0.5, 0.5]];
      const brandScale = config.brandScale ?? 1;
      const scaledWidth = beerIconWidth * brandScale;
      const scaledHeight = beerIconHeight * brandScale;
      for (const position of positions) {
        billboardImage.copy(
          beerIcon,
          0,
          0,
          beerIcon.width,
          beerIcon.height,
          billboardImage.width * position[0] - scaledWidth / 2,
          billboardImage.height * position[1] - scaledHeight / 2,
          scaledWidth,
          scaledHeight
        );
      }
      index += 1;
    }
    billboardsBuilt = true;
    if (billboardImages.length === 0) {
      return;
    }
    let count = 0;
    for (
      let i = billboardStart;
      i < road.segments.length;
      i += randomInt(billboardSpacingMin, billboardSpacingMax)
    ) {
      const billboardIndex = billboardRandomise
        ? randomInt(0, billboardImages.length - 1)
        : count % billboardImages.length;
      road.addSprite(
        i,
        billboardImages[billboardIndex],
        count % 2 === 0 ? -1 : 1,
        skin.billboardImages[billboardIndex].scale ?? 1
      );
      count += 1;
    }
  };

  const buildSprites = () => {
    if (spritesBuilt) {
      return;
    }
    if (skin.sceneryImages.length !== sceneryImages.length) {
      return;
    }
    for (let n = 10; n < road.segments.length; n += 6 + Math.floor(n / 100)) {
      let index = 0;
      for (const sceneryImage of sceneryImages) {
        const config = skin.sceneryImages[index];
        const vertMin = config.verticalOffsetMin ?? 0;
        const vertMax = config.verticalOffsetMax ?? 0;
        const vertOffset = vertMin + Math.random() * (vertMax - vertMin);
        console.log(vertMin, vertMax, vertOffset);
        road.addSprite(
          n,
          sceneryImage,
          (config.offset + Math.random()) *
            (Math.random() < 0.5 ? config.minMultiplier : config.maxMultiplier),
          config.scale ?? 1,
          vertOffset
        );
        index += 1;
      }
    }
    spritesBuilt = true;
  };

  const updatePosition = (updateSpeed: boolean): void => {
    const targetSpeed = p5.map(focusLevel, 0, 100, 0, maxSpeed, true);
    isAccelerating = targetSpeed > speed;
    isDecelerating = targetSpeed < speed;

    if (updateSpeed) {
      const dt = p5.deltaTime / 1000;
      position = Util.increase(position, dt * speed, road.trackLength);

      if (isAccelerating) {
        speed = Util.accelerate(speed, accel, dt);
      } else if (isDecelerating) {
        speed = Util.accelerate(speed, decel, dt);
      }

      speed = Math.max(0, Math.min(speed, maxSpeed));
    }
  };

  p5.draw = () => {
    // currScore = p5.map(p5.noise(p5.frameCount / 25), 0, 1, 0, 1);
    const inPractice = timeRemaining - pourTime >= 0;
    const duration = pourTime + practiceTime;
    if (p5.frameCount % 60 == 0) {
      console.log('curr fr: ', p5.frameRate());
    }

    p5.background(2, 20);

    const elapsedMillis = p5.round(p5.millis());
    const elapsed = p5.round(elapsedMillis / 1000);
    timeRemaining = p5.map(elapsed, 0, duration, duration, 0, true);
    const timeRemainingPct = p5.map(
      elapsedMillis,
      practiceTime * 1000,
      duration * 1000,
      0,
      100,
      true
    );

    updatePosition(!inPractice);

    // Draw background
    const skyImageWidth = !!skin.fitSkyToHeight
      ? skyImage.width * (p5.height / skyImage.height)
      : p5.width;
    const skyImageHeight = !!skin.fitSkyToHeight
      ? p5.height
      : skyImage.height * (p5.width / skyImage.width);

    p5.image(skyImage, 0, 0, skyImageWidth, skyImageHeight);

    const drawBackground = (
      image: Image,
      skyWidth: number,
      yOffset: number
    ): void => {
      const ratio = image.width / skyWidth;
      const backgroundHeight = image.height * (p5.width / image.width) * ratio;
      let x = 0;
      do {
        const backgroundWidth = p5.width * ratio;
        p5.image(
          image,
          x,
          yOffset - backgroundHeight,
          backgroundWidth,
          backgroundHeight
        );
        x += backgroundWidth;
      } while (x < p5.width);
    };

    parallaxImages.forEach((image) => {
      drawBackground(image, skyImage.width, skyImageHeight);
    });

    buildSprites();
    buildBillboards();

    // Draw road
    road.draw(drawRoadFirst, position, drawDistance);

    // BEGIN: Draw player
    const playerImages = inPractice ? playerStaticImages : playerStraightImages;
    if (playerIndex >= playerImages.length) {
      playerIndex = 0;
    }
    const playerImage = playerImages[playerIndex];
    const rawPlayerWidth = playerImage.width;

    road.spriteScale = skin.scaleMultiplier * (1 / rawPlayerWidth);

    const playerOffset = 20;
    const scale =
      road.spriteScale * (p5.width / 2) * (road.cameraDepth / road.playerZ);
    const bounce = skin.playerBounce
      ? inPractice
        ? 1.5 * Math.random()
        : 1.5 *
          Math.random() *
          (speed / maxSpeed) *
          (Math.random() < 0.5 ? -1 : 1)
      : 0;
    const playerWidth = rawPlayerWidth * scale * road.roadWidth;
    const playerHeight = playerImage.height * scale * road.roadWidth;
    const { gifProperties } = playerImage as ImageWithGif;
    if (
      gifProperties !== undefined &&
      gifProperties !== null &&
      gifProperties.numFrames > 0
    ) {
      playerImage.setFrame(
        (gifProperties.displayIndex + 1) % gifProperties.numFrames
      );
    }
    p5.image(
      playerImage,
      p5.width / 2 - playerWidth / 2,
      p5.height - playerHeight + bounce - playerOffset,
      playerWidth,
      playerHeight
    );
    playerIndex = (playerIndex + 1) % playerImages.length;
    // END: Draw player

    topDisplay.draw(
      inPractice ? pourTime : timeRemaining,
      focusLevel,
      inPractice ? 0 : timeRemainingPct,
      'mph'
    );

    if (inPractice) {
      lightsOut.draw(Math.floor((elapsed / practiceTime) * 6));
      playSoundEffectCallback(SoundEffectType.Idle);
      if (focusLevel > 0) {
        playSoundEffectCallback(SoundEffectType.Rev);
      }
    } else {
      stopSoundEffectCallback(SoundEffectType.Rev);
      if (focusLevel > 0) {
        stopSoundEffectCallback(SoundEffectType.Idle);
        playSoundEffectCallback(SoundEffectType.Accelerate);
      } else {
        stopSoundEffectCallback(SoundEffectType.Accelerate);
        playSoundEffectCallback(SoundEffectType.Idle);
      }
    }

    if (timeRemaining <= 0) {
      onCompleteCallback(position / segmentLength);
      p5.noLoop();
    }
  };
};

class Util {
  static project(
    p: RoadPosition | ProjectedRoadPosition,
    cameraX: number,
    cameraY: number,
    cameraZ: number,
    cameraDepth: number,
    width: number,
    height: number,
    roadWidth: number
  ): ProjectedRoadPosition {
    const resolvedCamera = {
      x: (p.world.x || 0) - cameraX,
      y: (p.world.y || 0) - cameraY,
      z: (p.world.z || 0) - cameraZ,
    };
    const resolvedScreenScale = cameraDepth / resolvedCamera.z;
    return {
      ...p,
      camera: resolvedCamera,
      screen: {
        scale: resolvedScreenScale,
        x: Math.round(
          width / 2 + (resolvedScreenScale * resolvedCamera.x * width) / 2
        ),
        y: Math.round(
          height / 2 - (resolvedScreenScale * resolvedCamera.y * height) / 2
        ),
        w: Math.round((resolvedScreenScale * roadWidth * width) / 2),
      },
    };
  }

  static increase(start: number, increment: number, max: number): number {
    let result = start + increment;
    while (result >= max) {
      result -= max;
    }
    while (result < 0) {
      result += max;
    }
    return result;
  }

  static easeIn(a: number, b: number, percent: number): number {
    return a + (b - a) * Math.pow(percent, 2);
  }
  static easeOut(a: number, b: number, percent: number): number {
    return a + (b - a) * (1 - Math.pow(1 - percent, 2));
  }
  static easeInOut(a: number, b: number, percent: number): number {
    return a + (b - a) * (-Math.cos(percent * Math.PI) / 2 + 0.5);
  }

  static accelerate(v: number, accel: number, dt: number): number {
    return v + accel * dt;
  }

  static exponentialFog(distance: number, density: number): number {
    return 1 / Math.pow(Math.E, distance * distance * density);
  }

  static percentRemaining(n: number, total: number): number {
    return (n % total) / total;
  }
}

class Road {
  p5: P5CanvasInstance<RaceGameProps>;
  segments: RoadSegmentDescription[];
  segmentLength: number;
  lanes: number;
  public roadWidth: number;
  rumbleLength: number;
  cameraHeight: number;
  public cameraDepth: number;
  fogDensity: number;
  public trackLength: number;
  public playerZ: number;
  public spriteScale = 0;
  public skin: RaceModeSkin;

  constructor(
    p5: P5CanvasInstance<RaceGameProps>,
    segmentLength: number,
    fieldOfView: number,
    skin: RaceModeSkin
  ) {
    this.p5 = p5;
    this.segments = [];
    this.segmentLength = segmentLength;
    this.lanes = 3;
    this.roadWidth = 2000;
    this.rumbleLength = 3;
    this.cameraHeight = 1000;
    this.cameraDepth = 1 / Math.tan(((fieldOfView / 2) * Math.PI) / 180);
    this.trackLength = 0;
    this.fogDensity = 5;
    this.playerZ = this.cameraHeight * this.cameraDepth;
    this.skin = skin;
    this.resetRoad();
  }

  resetRoad() {
    this.segments = [];

    this.addStraight(ROAD.LENGTH.SHORT / 4);
    this.addSCurves();
    this.addStraight(ROAD.LENGTH.LONG);
    this.addCurve(ROAD.LENGTH.MEDIUM, ROAD.CURVE.MEDIUM);
    this.addCurve(ROAD.LENGTH.LONG, ROAD.CURVE.MEDIUM);
    this.addStraight();
    this.addSCurves();
    this.addCurve(ROAD.LENGTH.LONG, -ROAD.CURVE.MEDIUM);
    this.addCurve(ROAD.LENGTH.LONG, ROAD.CURVE.MEDIUM);
    this.addStraight();
    this.addSCurves();
    this.addCurve(ROAD.LENGTH.LONG, -ROAD.CURVE.EASY);

    this.trackLength = this.segmentLength * this.segments.length;
  }

  addSprite(
    n: number,
    source: Image,
    offset: number,
    scale: number,
    verticalOffset?: number
  ) {
    this.segments[n].sprites.push({ source, offset, scale, verticalOffset });
  }

  addRoad(enter: number, hold: number, leave: number, curve: number): void {
    for (let n = 0; n < enter; n++) {
      this.addSegment(Util.easeIn(0, curve, n / enter));
    }
    for (let n = 0; n < hold; n++) {
      this.addSegment(curve);
    }
    for (let n = 0; n < leave; n++) {
      this.addSegment(Util.easeInOut(curve, 0, n / leave));
    }
  }

  addStraight(num?: number): void {
    num = num || ROAD.LENGTH.MEDIUM;
    this.addRoad(num, num, num, 0);
  }

  addCurve(num?: number, curve?: number): void {
    const normalisedNum = num || ROAD.LENGTH.MEDIUM;
    const normalisedCurve = curve || ROAD.CURVE.MEDIUM;
    this.addRoad(normalisedNum, normalisedNum, normalisedNum, normalisedCurve);
  }

  addSCurves(): void {
    this.addRoad(
      ROAD.LENGTH.MEDIUM,
      ROAD.LENGTH.MEDIUM,
      ROAD.LENGTH.MEDIUM,
      -ROAD.CURVE.EASY
    );
    this.addRoad(
      ROAD.LENGTH.MEDIUM,
      ROAD.LENGTH.MEDIUM,
      ROAD.LENGTH.MEDIUM,
      ROAD.CURVE.MEDIUM
    );
    this.addRoad(
      ROAD.LENGTH.MEDIUM,
      ROAD.LENGTH.MEDIUM,
      ROAD.LENGTH.MEDIUM,
      ROAD.CURVE.EASY
    );
    this.addRoad(
      ROAD.LENGTH.MEDIUM,
      ROAD.LENGTH.MEDIUM,
      ROAD.LENGTH.MEDIUM,
      -ROAD.CURVE.EASY
    );
    this.addRoad(
      ROAD.LENGTH.MEDIUM,
      ROAD.LENGTH.MEDIUM,
      ROAD.LENGTH.MEDIUM,
      -ROAD.CURVE.MEDIUM
    );
  }

  addSegment(curve: number): void {
    const n = this.segments.length;
    this.segments.push({
      index: n,
      p1: {
        world: {
          z: n * this.segmentLength,
        },
      },
      p2: {
        world: {
          z: (n + 1) * this.segmentLength,
        },
      },
      curve,
      clip: 0,
      sprites: [],
      color:
        Math.floor(n / this.rumbleLength) % 2
          ? this.skin.colors.DARK
          : this.skin.colors.LIGHT,
    });
  }

  findSegment(z: number): RoadSegmentDescription {
    return this.segments[
      Math.floor(z / this.segmentLength) % this.segments.length
    ];
  }

  drawRoad(position: number, drawDistance: number): void {
    const baseSegment = this.findSegment(position);
    const basePercent = Util.percentRemaining(position, this.segmentLength);
    const playerX = 0;
    const maxy = this.p5.height;

    let x = 0;
    let dx = -(baseSegment.curve * basePercent);

    for (let n = 0; n < drawDistance; n++) {
      const segment =
        this.segments[(baseSegment.index + n) % this.segments.length];

      segment.looped = segment.index < baseSegment.index;
      segment.fog = Util.exponentialFog(n / drawDistance, this.fogDensity);
      segment.clip = maxy;

      segment.p1 = Util.project(
        segment.p1,
        playerX * this.roadWidth - x,
        this.cameraHeight,
        position - (segment.looped ? this.trackLength : 0),
        this.cameraDepth,
        this.p5.width,
        this.p5.height,
        this.roadWidth
      );
      segment.p2 = Util.project(
        segment.p2,
        playerX * this.roadWidth - x - dx,
        this.cameraHeight,
        position - (segment.looped ? this.trackLength : 0),
        this.cameraDepth,
        this.p5.width,
        this.p5.height,
        this.roadWidth
      );

      x = x + dx;
      dx = dx + segment.curve;

      if (
        (segment.p1 as ProjectedRoadPosition).camera.z <= this.cameraDepth || // behind us
        (segment.p2 as ProjectedRoadPosition).screen.y >= maxy
      ) {
        // clip by (already rendered) segment
        continue;
      }

      const renderSegment = new RoadSegment(this.p5, this.skin);
      renderSegment.draw(
        this.lanes,
        (segment.p1 as ProjectedRoadPosition).screen.x,
        (segment.p1 as ProjectedRoadPosition).screen.y,
        (segment.p1 as ProjectedRoadPosition).screen.w,
        (segment.p2 as ProjectedRoadPosition).screen.x,
        (segment.p2 as ProjectedRoadPosition).screen.y,
        (segment.p2 as ProjectedRoadPosition).screen.w,
        segment.fog ?? 0,
        segment.color
      );
    }
  }

  drawSprites(position: number, drawDistance: number) {
    const baseSegment = this.findSegment(position);
    for (let n = drawDistance - 1; n > 0; n--) {
      const segment =
        this.segments[(baseSegment.index + n) % this.segments.length];
      for (let i = 0; i < segment.sprites.length; i++) {
        const sprite = segment.sprites[i];
        const spriteScale = (segment.p1 as ProjectedRoadPosition).screen.scale;
        const spriteX =
          (segment.p1 as ProjectedRoadPosition).screen.x +
          (spriteScale * sprite.offset * this.roadWidth * this.p5.width) / 2;
        const spriteY = (segment.p1 as ProjectedRoadPosition).screen.y;

        const spriteObj = new Sprite(this.p5, sprite.source, sprite.offset);
        spriteObj.draw(
          this.roadWidth,
          spriteScale * this.spriteScale * sprite.scale,
          spriteX,
          spriteY,
          sprite.offset < 0 ? -1 : 0,
          (sprite.verticalOffset ?? 0) - 1,
          segment.clip
        );
      }
    }
  }

  draw(drawRoadFirst: boolean, position: number, drawDistance: number): void {
    if (drawRoadFirst) {
      this.drawRoad(position, drawDistance);
    }
    this.drawSprites(position, drawDistance);
    if (!drawRoadFirst) {
      this.drawRoad(position, drawDistance);
    }
  }
}

class RoadSegment {
  p5: P5CanvasInstance<RaceGameProps>;
  skin: RaceModeSkin;

  constructor(p5: P5CanvasInstance<RaceGameProps>, skin: RaceModeSkin) {
    this.p5 = p5;
    this.skin = skin;
  }

  drawPolygon(
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    x3: number,
    y3: number,
    x4: number,
    y4: number,
    color: string
  ): void {
    this.p5.push();
    this.p5.beginShape();
    this.p5.noStroke();
    this.p5.fill(color);
    this.p5.vertex(x1, y1);
    this.p5.vertex(x2, y2);
    this.p5.vertex(x3, y3);
    this.p5.vertex(x4, y4);
    this.p5.endShape(this.p5.CLOSE);
    this.p5.pop();
  }

  draw(
    lanes: number,
    x1: number,
    y1: number,
    w1: number,
    x2: number,
    y2: number,
    w2: number,
    fog: number,
    color: ColorStruct
  ): void {
    // Draw the grass
    this.p5.push();
    this.p5.noStroke();
    this.p5.fill(color.grass);
    this.p5.rect(0, y2, this.p5.width, y1 - y2);
    this.p5.pop();

    const r1 = this.rumbleWidth(w1, lanes);
    const r2 = this.rumbleWidth(w2, lanes);

    this.p5.push();
    if (!!this.skin.glowLines) {
      this.p5.drawingContext.shadowBlur = 10;
      this.p5.drawingContext.shadowColor = color.rumble;
    }
    this.drawPolygon(
      x1 - w1 - r1,
      y1,
      x1 - w1,
      y1,
      x2 - w2,
      y2,
      x2 - w2 - r2,
      y2,
      color.rumble
    );

    this.drawPolygon(
      x1 + w1 + r1,
      y1,
      x1 + w1,
      y1,
      x2 + w2,
      y2,
      x2 + w2 + r2,
      y2,
      color.rumble
    );

    this.p5.pop();

    this.drawPolygon(
      x1 - w1,
      y1,
      x1 + w1,
      y1,
      x2 + w2,
      y2,
      x2 - w2,
      y2,
      color.road
    );

    if (color.lane !== undefined) {
      this.p5.push();
      if (!!this.skin.glowLines) {
        this.p5.drawingContext.shadowBlur = 10;
        this.p5.drawingContext.shadowColor = color.lane;
      }
      const l1 = this.laneMarkerWidth(w1, lanes);
      const l2 = this.laneMarkerWidth(w2, lanes);
      const lanew1 = (w1 * 2) / lanes;
      const lanew2 = (w2 * 2) / lanes;
      let lanex1 = x1 - w1 + lanew1;
      let lanex2 = x2 - w2 + lanew2;
      for (
        let lane = 1;
        lane < lanes;
        lanex1 += lanew1, lanex2 += lanew2, lane++
      ) {
        this.drawPolygon(
          lanex1 - l1 / 2,
          y1,
          lanex1 + l1 / 2,
          y1,
          lanex2 + l2 / 2,
          y2,
          lanex2 - l2 / 2,
          y2,
          color.lane
        );
      }
      this.p5.pop();
    }

    if (this.skin.colors.FOG !== undefined && fog > 0 && fog < 1) {
      this.p5.push();
      this.p5.noStroke();
      const fogColour = this.p5.color(this.skin.colors.FOG);
      fogColour.setAlpha((1 - fog) * 255);
      this.p5.fill(fogColour);
      this.p5.rect(0, y1, this.p5.width, y2 - y1);
      this.p5.pop();
    }
  }

  rumbleWidth(projectedRoadWidth: number, lanes: number): number {
    return projectedRoadWidth / Math.max(6, 2 * lanes);
  }

  laneMarkerWidth(projectedRoadWidth: number, lanes: number): number {
    return projectedRoadWidth / Math.max(32, 8 * lanes);
  }
}

class Sprite {
  p5: P5CanvasInstance<RaceGameProps>;
  image: Image;
  offset: number;

  constructor(
    p5: P5CanvasInstance<RaceGameProps>,
    image: Image,
    offset: number
  ) {
    this.p5 = p5;
    this.image = image;
    this.offset = offset;
  }

  draw(
    roadWidth: number,
    scale: number,
    destX: number,
    destY: number,
    offsetX?: number,
    offsetY?: number,
    clipY?: number
  ) {
    //  scale for projection AND relative to roadWidth (for tweakUI)
    const destW = ((this.image.width * scale * this.p5.width) / 2) * roadWidth;
    const destH = ((this.image.height * scale * this.p5.width) / 2) * roadWidth;

    destX = destX + destW * (offsetX || 0);
    destY = destY + destH * (offsetY || 0);

    const clipH = clipY ? Math.max(0, destY + destH - clipY) : 0;
    if (clipH < destH) {
      this.p5.image(this.image, destX, destY, destW, destH - clipH);
    }
  }
}

class LightsOut {
  p5: P5CanvasInstance<RaceGameProps>;

  constructor(p5: P5CanvasInstance<RaceGameProps>) {
    this.p5 = p5;
  }

  draw(count: number) {
    this.p5.push();
    this.p5.fill(40, 40, 40);
    this.p5.noStroke();

    const lightWidth = this.p5.width * 0.4;
    const lightSize = lightWidth * (2 / 15);
    const lightRadius = lightSize / 2;
    const columns = 5;
    const lightGap = (lightWidth - columns * lightSize) / (columns + 1);
    const lightHeight = 3 * lightGap + 2 * lightSize;

    const xOffset = (this.p5.width - lightWidth) / 2;
    const yOffset = (this.p5.height - lightHeight) / 2;

    this.p5.rect(xOffset, yOffset, lightWidth, lightHeight, 10);

    for (let i = 0; i < 5; i += 1) {
      const x = xOffset + (i + 1) * lightGap + i * lightSize + lightRadius;
      const y1 = yOffset + lightGap + lightRadius;
      const y2 = yOffset + 2 * lightGap + lightSize + lightRadius;

      this.p5.fill(32, 32, 32);
      this.p5.circle(x, y1, lightSize * 1.2);
      this.p5.circle(x, y2, lightSize * 1.2);

      if (i < count) {
        this.p5.fill(200, 0, 0);
      } else {
        this.p5.fill(120, 120, 120);
      }
      this.p5.circle(x, y1, lightSize);
      this.p5.circle(x, y2, lightSize);
    }
    this.p5.pop();
  }
}
