import {
  FunctionComponent,
  useState,
  MutableRefObject,
  useCallback,
} from 'react';
import ReactSimpleKeyboard, {
  KeyboardLayoutObject,
  SimpleKeyboard,
} from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

interface KeyboardProps {
  onChange: (input: string, e?: MouseEvent) => void;
  layout?: KeyboardLayoutObject;
  inputName?: string;
  initialLayoutName?: string;
  maxLength?: number | Record<string, number>;
  display?: {
    [button: string]: string;
  };
  keyboardRef: MutableRefObject<SimpleKeyboard | undefined>;
}

const Keyboard: FunctionComponent<KeyboardProps> = ({
  onChange,
  layout,
  inputName,
  initialLayoutName = 'default',
  maxLength,
  display,
  keyboardRef,
}) => {
  const [layoutName, setLayoutName] = useState(initialLayoutName);
  const [wasLocked, setWasLocked] = useState(false);

  const onKeyPress = useCallback(
    (button: string) => {
      if (button === '{shift}' || button === '{lock}') {
        setLayoutName(layoutName === 'default' ? 'shift' : 'default');
        setWasLocked(button === '{lock}');
      } else if (!wasLocked) {
        setLayoutName('default');
      }
    },
    [wasLocked]
  );

  return (
    <ReactSimpleKeyboard
      keyboardRef={(r) => (keyboardRef.current = r)}
      layoutName={layout ? undefined : layoutName}
      maxLength={maxLength}
      inputName={inputName}
      layout={layout}
      display={display}
      preventMouseDownDefault={true}
      onChange={onChange}
      onKeyPress={onKeyPress}
      onRender={() => console.log('Rendered')}
    />
  );
};

export default Keyboard;
